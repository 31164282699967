import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import TextField from '@mui/material/TextField';
import Avatar from '@mui/material/Avatar';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { useNavigate } from 'react-router-dom';
import { ORDERS_RECEIVED } from '../../helpers/constants/RoutePaths';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { FormControl, FormHelperText, IconButton, InputAdornment, InputLabel, OutlinedInput, Stack } from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useState } from 'react';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import axios from 'axios';
import { LOGIN_URL } from '../../helpers/constants/ApiPaths';
import { useSnackbar } from 'notistack';
import { AUTH_TOKEN, AUTH_USER } from '../../helpers/constants/LocalStorage';
import { CustomAlertMessage } from '../../helpers/functions/CustomFunctions';
import LoadingButton from '@mui/lab/LoadingButton';
import "./Signin.scss";
function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://mui.com/">
      Crackers Mart
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

//state
const initialValues = {
  email: '',
  pwd: '',
};
//Schema
const validationSchema = Yup.object({
  email : Yup.string().trim().required('Email is required').email('Give valid email'),
  pwd: Yup.string().required('Password is required')
});

const theme = createTheme();
export default function Signin() {
  const navigate = useNavigate();  
  const { enqueueSnackbar } = useSnackbar();

  const [showPassword,setShowPassword] = useState(false); 
  const [loading,setLoading] = useState(false);

  const saveFormValues = (values) => {
    localStorage.setItem(AUTH_TOKEN,'34f453f5345f35');
    const authUser = JSON.stringify({email: 'staticemail@g.co'})
    localStorage.setItem(AUTH_USER,authUser)
    navigate(ORDERS_RECEIVED);
    return;
    setLoading(true);
    axios.post(LOGIN_URL,values).then((res) => {
      const {data:{message="",token=""}, success=false} = res.data;
      if(success){
        // CustomAlertMessage(message, 'success', enqueueSnackbar);
        localStorage.setItem(AUTH_TOKEN,token);
        const authUser = JSON.stringify({email: values.email})
        localStorage.setItem(AUTH_USER,authUser)
        navigate(ORDERS_RECEIVED);   
      }else{
        CustomAlertMessage(message, 'error', enqueueSnackbar);
      }
    }).catch((error) => {
      CustomAlertMessage(error.message, 'error', enqueueSnackbar);
    }).finally(() => {
      setLoading(false);
    })
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  }; 
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  //Formik
  const {handleSubmit,handleChange, values,errors,touched,setFieldValue} = useFormik({        
    initialValues,
    enableReinitialize : true,
    validationSchema,
    onSubmit:(values) => saveFormValues(values)        
  });

  const { email= '',  pwd= ''} = values;
  return (
    <ThemeProvider theme={theme}>
      <Grid container component="main" sx={{ height: '100vh' }}>
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          lg={7}
          sx={{          
            backgroundColor: 'rgb(151 152 164)',        
          }}
          className="hor-ver-center"
          id="ord-por-home-img-block"
        >
          <img src={`${process.env.PUBLIC_URL}/images/crackersmartlogo.png`} alt="Crackers Mart" className=''/>
          </Grid>
        <Grid item xs={12} sm={12} md={6} lg={5} component={Paper} elevation={6} square className="hor-ver-center">
        <Stack spacing={2}>
          <div>
            <center><img src={`${process.env.PUBLIC_URL}/images/logowithtext.png`} alt="Logo" width="200" /></center>
          </div>
        <div>          
          <Box
            sx={{
              my: 6,
              mx: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
            className="admin-signin-box "
          >            
            <Avatar sx={{ m: 1, bgcolor: '#e31e24' }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Sign in
            </Typography>
            <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>    
            <Grid container spacing={3}>   
              <Grid item xs={12}>        
                <TextField
                  id="email-helper-text"
                  label="Email"
                  value={email}
                  onChange={handleChange}
                  fullWidth
                  name='email'
                  variant="outlined"
                  error={errors.email && touched.email}
                  helperText={(errors.email && touched.email) ? errors.email : ''}
                /> 
              </Grid>
              <Grid item xs={12}> 
                <FormControl sx={{width:'100%'}} variant="outlined" error={errors.pwd && touched.pwd}>
                  <InputLabel htmlFor="pwd-pwd">Password</InputLabel>
                  <OutlinedInput
                      id="pwd-pwd"
                      type={showPassword ? 'text' : 'password'}
                      value={pwd}
                      name="pwd"
                      fullWidth
                      label="Password"
                      onChange={handleChange}
                      endAdornment={
                      <InputAdornment position="end">
                          <IconButton
                          aria-label="pwd"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                          >
                          {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                          </IconButton>
                      </InputAdornment>
                      }
                  />
                  <FormHelperText>{(errors.pwd && touched.pwd) ? errors.pwd : ''}</FormHelperText>
                </FormControl>         
              </Grid>
              <Grid item xs={12}> 
              <LoadingButton          
                loading={loading}
                type="submit"
                fullWidth
                variant="contained"
                className="common-app-bg"
              >
                Sign In
              </LoadingButton>                
              <Copyright sx={{ mt: 5 }} />
              </Grid>
            </Grid>
            </Box>
          </Box>
          </div>
      </Stack>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}
