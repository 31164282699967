import axios from "axios"
import { ADD_CART, ADMIN_ORDERS_LIST, ADMIN_PRODUCTS_LIST, ADMIN_PRODUCT_DELETE, ADMIN_PRODUCT_STATUS_UPDATE, ADMIN_UPDATE_ORDER, ADMIN_UPDATE_PAYMENT, CART_ITEMS_API, MY_ORDERS, PRODUCT_FILTER, REMOVE_CART, SHOW_ALL_ORDERS } from "../../helpers/constants/ApiPaths"
import { authCustomer, authUserEmail, authUserToken, showToast } from "../../helpers/functions/CustomFunctions"
import AxiIns from "../../helpers/API"
import { setOrdersList } from "./reducers/ShowAllOrdersReducer"
import { setCartItems } from "./reducers/GetCartItemsReducer"
import { setCustomerOrdersList } from "./reducers/GetCustomerOrdersReducer"
import { setProducts } from "./reducers/GetProductsReducer"

//Admin
export function getOrders(status){
    return async dispatch => {
        // dispatch({ type : SHOW_ALL_ORDERS_LOADING})
        try{
            const payload = {
                url : ADMIN_ORDERS_LIST,
                method:'get',
                params: {
                    status 
                }
            }
            await AxiIns(payload).then(response => {
                const {status,data} = response.data;
                if(status === "success"){
                    dispatch(setOrdersList(data))
                }
            }).catch(error => {
                if(error.response){

                }
            })
        }catch(error){

        }
    }
}
export function updateOrder(order_id,user_id,status,isFromOrderPotal){
    return async dispatch => {
        // dispatch({ type : SHOW_ALL_ORDERS_LOADING})
        try{
            // const {id} = authCustomer();
            const payload = {
                method: 'POST',
                url: ADMIN_UPDATE_ORDER,
                data: {
                    user_id,
                    order_id,
                    order_status: status                    
                }
            };
            await AxiIns(payload).then(response => {
                const {status = '',data = ''} = response.data;
                if(status === "success"){                    
                    showToast(data,'success'); 
                    dispatch(getOrders('dispatch-pending'))
                    if(isFromOrderPotal === false){
                        dispatch(getCustomerOrders())
                    }
                }
            }).catch(error => {
                if(error.response){

                }
            })
        }catch(error){

        }
    }
}
export function updatePayment(order_id,user_id,updateFrom){
    return async dispatch => {
        // dispatch({ type : SHOW_ALL_ORDERS_LOADING})
        try{
            // const {id} = authCustomer();
            const payload = {
                method: 'POST',
                url: ADMIN_UPDATE_PAYMENT,
                data: {
                    user_id,
                    order_id,
                    payment_status:'completed'                    
                }
            };
            await AxiIns(payload).then(response => {
                const {status = '',data = ''} = response.data;
                if(status === "success"){
                    showToast(data,'success'); 
                    dispatch(getOrders(updateFrom))
                }
            }).catch(error => {
                if(error.response){

                }
            })
        }catch(error){

        }
    }
}
export function getProducts(){
    return async dispatch => {
        // dispatch({ type : SHOW_ALL_ORDERS_LOADING})
        try{
            const payload = {
                method: 'POST',
                url: ADMIN_PRODUCTS_LIST,
            };
            await AxiIns(payload).then(response => {
                dispatch(setProducts(response.data.data))
            }).catch(error => {
                if(error.response){

                }
            })
        }catch(error){

        }
    }
}
export function updateProductStatus(formData){
    return async dispatch => {
        // dispatch({ type : SHOW_ALL_ORDERS_LOADING})
        try{
            // const {id} = authCustomer();
            const payload = {
                method: 'POST',
                url: ADMIN_PRODUCT_STATUS_UPDATE,
                data: formData
            };
            await AxiIns(payload).then(response => {
                const {status = '',data = ''} = response.data;
                if(status === "success"){                    
                    showToast(data,'success'); 
                    dispatch(getProducts())                    
                }
            }).catch(error => {
                if(error.response){

                }
            })
        }catch(error){

        }
    }
}
export function deleteProduct(product_id){
    return async dispatch => {
        // dispatch({ type : SHOW_ALL_ORDERS_LOADING})
        try{
            // const {id} = authCustomer();
            const payload = {
                method: 'POST',
                url: ADMIN_PRODUCT_DELETE,
                data: {
                    product_id
                }
            };
            await AxiIns(payload).then(response => {
                const {status = '',data = ''} = response.data;
                if(status === "success"){                    
                    showToast(data,'success'); 
                    dispatch(getProducts())                    
                }
            }).catch(error => {
                if(error.response){

                }
            })
        }catch(error){

        }
    }
}

//Customer
export function getCartItems(){
    return async dispatch => {
        // dispatch({ type : SHOW_ALL_ORDERS_LOADING})
        try{
            const {id} = authCustomer();
            const payload = {
                method: 'GET',
                url: CART_ITEMS_API,
                params: {
                    user_id : id
                }
            };
            await AxiIns(payload).then(response => {
                dispatch(setCartItems(response.data))
            }).catch(error => {
                if(error.response){

                }
            })
        }catch(error){

        }
    }
}
export function addItemToCart(formData){
    return async dispatch => {
        // dispatch({ type : SHOW_ALL_ORDERS_LOADING})
        try{
            const {id} = authCustomer();
            const payload = {
                method: 'POST',
                url: ADD_CART,
                data: {
                    user_id : id,
                    ...formData                    
                }
            };
            let resFlag = false;
            await AxiIns(payload).then(response => {
                const {success = '',error = ''} = response.data;
                if(success){
                    showToast(success,'success'); 
                    dispatch(getCartItems());
                    resFlag = true;   
                }else{
                    showToast(error,'error');
                }
            }).catch(error => {
                if(error.response){

                }
            })
            return resFlag;
        }catch(error){

        }
    }
}
export function removeItemFromCart(formData){
    return async dispatch => {
        // dispatch({ type : SHOW_ALL_ORDERS_LOADING})
        try{
            const {id} = authCustomer();
            const payload = {
                method: 'POST',
                url: REMOVE_CART,
                data: {
                    user_id : id,
                    ...formData                    
                }
            };
            await AxiIns(payload).then(response => {
                const {success} = response.data;
                if(success){
                    showToast(success,'success');    
                    dispatch(getCartItems());
                }
            }).catch(error => {
                if(error.response){

                }
            })
        }catch(error){

        }
    }
}
export async function getFilteredProducts(type){    
    try{
        const payload = {
            method: 'POST',
            url: PRODUCT_FILTER,
            params: {
                type
            }
        };
        return await AxiIns(payload).then(response => {
            return response.data.data;
        }).catch(error => {
            if(error.response){

            }
        })
    }catch(error){

    }
    
}
export function getCustomerOrders(){
    return async dispatch => {
        // dispatch({ type : SHOW_ALL_ORDERS_LOADING})
        try{
            const {id} = authCustomer();
            const payload = {
                method: 'GET',
                url: MY_ORDERS,
                params: {
                  user_id : id
                }              
            };
            await AxiIns(payload).then(response => {
                const {status,data} = response.data;
                if(status === "success"){
                    dispatch(setCustomerOrdersList(data))
                }
            }).catch(error => {
                if(error.response){

                }
            })
        }catch(error){

        }
    }
}