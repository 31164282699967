import React, { Fragment } from 'react'
import { Grid, Typography } from '@mui/material'
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

export default function OrderSummaryItems({data}) {
    const {        
        total_amount = 0,
        total_items = 0,       
        package_charges = 0   
    } = data;
    const trimmedData = (data) => parseFloat(data.replace(',','')).toFixed(2);
    const billValue = (trimmedData(total_amount) - trimmedData(package_charges)).toFixed(2);
    
    return (
        <Fragment>
            <Grid container spacing={2}>
                <Grid item xs={3}>
                    <Item className='count-card'>
                    <Typography variant="caption" display="block" gutterBottom>Total Items</Typography>
                    <Typography variant="h6" gutterBottom component="div">{total_items}</Typography>
                    </Item>
                </Grid> 
                <Grid item xs={3}>
                    <Item className='count-card'>
                    <Typography variant="caption" display="block" gutterBottom>Bill Value</Typography>
                    <Typography variant="h6" gutterBottom component="div">&#8377;{billValue}</Typography>                       
                    </Item>
                </Grid> 
                <Grid item xs={3}>
                    <Item className='count-card'>
                    <Typography variant="caption" display="block" gutterBottom>Pack Charge</Typography>
                    <Typography variant="h6" gutterBottom component="div">&#8377;{package_charges}</Typography>
                    </Item>
                </Grid> 
                <Grid item xs={3}>
                    <Item className='count-card'>
                    <Typography variant="caption" display="block" gutterBottom>Order Value</Typography>
                    <Typography variant="h6" gutterBottom component="div">&#8377;{total_amount}</Typography>
                    </Item>
                </Grid>              
            </Grid>
        </Fragment>
    )
}
