import React from 'react'
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Alert from '@mui/material/Alert';
import { styled } from '@mui/material/styles';
import AlertTitle from '@mui/material/AlertTitle';
import Chip from '@mui/material/Chip';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { WHATS_APP_NUMBER } from '../../helpers/constants/AppValues';
import "./Common.scss";
const CustomAlert = styled(Alert)(({ theme }) => ({
    borderRadius: '10px !important', 
    textAlign:'center',    
    width: '100% !important',
    '& .MuiAlert-message': {
        width: '100% !important'
    }
    
}));
export default function ShippingPolicy() {
  return (
    <div className='dynamic-content'>  
         <Container maxWidth="lg">
            <Box sx={{ bgcolor: '#fff', padding: '5rem 1rem 1rem 1rem' }}>
                <center><h4><b>SHIPPING POLICY</b></h4></center>
                <CustomAlert icon={false} severity="info" >
                    <div className='ship-info'>
                        <div className='main-sec'>
                            <label>FOR DELIVERY &amp; SHIPPING STATUS</label>
                            <p><b>sales@crackersmart.com</b></p>
                        </div>
                        <div className='main-sec'>
                            <label>Reply Only In</label>
                            <div className='whatsapp-chip'>
                                <a href="https://wa.me/918870301420" target="_blank" rel="noreferrer" className="no-text-decoration">
                                <Chip icon={<WhatsAppIcon />} label={WHATS_APP_NUMBER} />
                                </a>
                            </div>
                        </div>
                        <div className='main-sec'>
                            <label>Timing</label>
                            <p><b>From 9:00am To 5:00pm</b></p>
                        </div>
                    </div>
                </CustomAlert>
                <p>We strive to deliver products purchased from www.crackersmart.in in excellent condition and in the fastest time possible. We here by request you to go through our shipping policy before making any purchase from our website.</p>

                <div className='ship-sec'>
                    <h5>SHIPPING</h5>
                    <p>Shipping charges Extra.</p>
                    <p>Freight charges to be prepaid to our account before shipping. Freight charges will be added in your invoice.</p>
                    <p>No Returns Basis.</p>
                </div>

                <div className='ship-sec'>
                    <h5>THEN WHERE SHOULD WE COLLECT THE GOODS?</h5>
                    <p>IT CAN BE COLLECTED FROM THE TRANSPORTS GODOWN OR FROM LORRY SHEADS. WE SHALL INFORM YOU THE TRANSPORTER NAME, LOCAL CONTACT PHONE NUMBER, WAY BILL NUMBER ETC ONCE THE GOODS ARE DISPATCHED FROM SIVAKASI . It will be sent via SMS or EMail.</p>
                </div>

                <div className='ship-sec'>
                    <h5>HOW DO WE SEND OUR GOODS?</h5>
                    <p>All confirmed orders with receipt of 100% payment in advance, which we receive before 12 Noon of each day are dispatched on the same day from our central godown in SIVAKASI to respective logistic assigned for respective destinations. - Our logistic will issue an "LR Copy" against the receipt of goods from us. All the goods will be sent in "Paid" basics, meaning the freight charges from SIVAKASI to your destination (Example say Coimbatore) will be paid by yourself before taking delivery of goods, The "LR COPY" with details of logistic name, contact person, contact addresses and contact phone number will be scanned and sent you by Email/SMS. The goods will reach your destination as specified in the delivery chart based on the distance of your destination from SIVAKASI.</p>
                </div>

                <div className='ship-sec'>
                    <h5>HOW DO I CHECK THE STATUS OF MY ORDER?</h5>
                    <p>All orders received with 100% payment are processed within 24 - 36hours. You can check the status of your order after 24 hours to check its STATUS. We will also send you the scanned copy of "LR COPY" by email/SMS. You can also whatsapp to {WHATS_APP_NUMBER} at any time to know your dispatch details.</p>
                </div>

                <div className='ship-sec'>
                    <h5>WHAT WILL BE THE DELIVERY/TRANSPORTATION CHARGES?</h5>
                    <p>Delivery or Transportation charge varies with each destination. WHAT IS THE ESTIMATED DELIVERY TIME? Each destination has its own delivery time. Please refer delivery chart for more assistance. Usually the confirmed paid orders we receive before 12 Noon on each day will be dispatched on the same day from our warehouse in SIVAKASI. Business days exclude public holidays and Sundays. Estimated delivery time depends on the destination to which you want the order shipped to from the location of our warehouse.</p>
                </div>

                <div className='ship-sec'>
                    <h5>SHIPPING AND DELIVERY DURATION</h5>
                    <p>After the order confirmation, Goods will be shipped within 48 hours.</p>
                </div>

                <div className='ship-sec'>
                    <h5>CANCELLATION DURATION</h5>
                    <p>Customer can request for the cancellation within 2 hours of the order placed. After the order confirmation orders can not be cancelled. You can cancel an order until it has been processed in our warehouse by whatsapp our customer care executive in {WHATS_APP_NUMBER} . You should cancel the order before the goods are dispatched from our warehouse to our logistic. This includes items purchased on sale.</p>
                </div>

                <div className='ship-sec'>
                    <h5>REFUND POLICY</h5>
                    <p>Refund will be made in 10 working days through online mode.</p>
                </div>

                <div className='ship-sec'>
                    <h5>WHATS YOUR RETURN POLICY?</h5>
                    <p>We do not take any return of goods.</p>
                </div>

                <div className='ship-sec'>
                    <h5>DO YOU DELIVER INTERNATIONALLY?</h5>
                    <p>As of now, we don't deliver items internationally. You will be able to make your purchases on our site from anywhere in the world, but please ensure the delivery address is in India.</p>
                </div>

                <Alert severity="info">
  <AlertTitle>Note</AlertTitle>
  We have every right to modify or delete our shipping policy without any prior information.
</Alert>
            </Box>
        </Container>   
    </div>
  )
}
