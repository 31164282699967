const order = {
    address : "123 A, West Block, North Street",
    city : "Sivakasi",
    created_at : "2022-05-20",
    custemail : "agentcody999@gmail.com",
    custname : "Adithyan",
    custphone : "9192919291",
    orderId : "6287503b653b383d429bb458",
    orderstatus : "Received",
    state : "Tamil Nadu",
}

export const SHOW_ALL_ORDERS_INITIALS = {    
    'Cancelled': [
        {
            ...order,
            orderstatus : "Cancelled"
        }
    ],
    'Completed': [
        {
            ...order,
            orderstatus : "Completed"
        }
    ],
    'Dispatch Pending': [
        {
            ...order,
            orderstatus : "Dispatch Pending"
        }
    ],
    'Payment Pending': [
        {
            ...order,
            orderstatus : "Payment Pending"
        }
    ],
    'Received': [
        order,
        order,
        order
    ],    
}

//Customer
export const CART_ITEMS_INITIAL = {
    loading : false,
    items : []
}
