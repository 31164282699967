import React from 'react'
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import { styled } from '@mui/material/styles';

const CustomIconButton= styled(IconButton)(({ theme }) => ({
    backgroundColor: 'dodgerblue !important', 
    color: '#fff !important',  
    margin:'0 5px 0 5px'  
}));
export default function CustomEditButton({handleClick}) {
  return (
    <CustomIconButton aria-label="delete" size="small" color='primary' onClick={handleClick}>
        <EditIcon fontSize="inherit" />
    </CustomIconButton>
  )
}
