import { Typography } from '@mui/material'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { getOrders } from '../../redux/actions';
import CustomPageLoading from '../common/CustomPageLoading';
import NoRecordFound from '../common/NoRecordFound';
import OrdersReceivedList from '../common/OrdersReceivedList'

export default function OrdersReceived() {
  const dispatch = useDispatch();
  const {loading = false, list=[]} = useSelector(state => state.orders);

  useEffect(() => {  
   dispatch(getOrders('all-orders'))
  }, []);  

  return (
    <div>
      <CustomPageLoading flag={loading} />
      <Typography variant="h5" gutterBottom component="div">All Orders</Typography>     
      {!loading ?
        !list.length ?
          <>
          <NoRecordFound />
          </>
        :
          list.map((record,index) => 
          <OrdersReceivedList key={`ord_rcv_${index}`} record={record} statusType="all-orders"/>
          )
      :''
      }
    </div>
  )
}
