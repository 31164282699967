import { Button, Card, CardMedia, TextField, Typography } from '@mui/material'
import { Container } from '@mui/system'
import React, { useState } from 'react'
import Stack from '@mui/material/Stack';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import 'react-toastify/dist/ReactToastify.css';
import { showToast } from '../../../helpers/functions/CustomFunctions';
import { ADMIN_PRODUCT_ADD, ADMIN_PRODUCT_UPDATE } from '../../../helpers/constants/ApiPaths';
import AxiIns from '../../../helpers/API';
import { Step } from '@mui/material/Step';
import { getProducts } from '../../../redux/actions';
import { useDispatch } from 'react-redux';


const validationSchema = Yup.object({
  brand_name : Yup.string().required('Brand Name is required'),
  category_name: Yup.string().required('Category is required'),
  product_name: Yup.string().required('Product Name is required'),
//   product_link: Yup.string().required('Product URL is required'),
  product_mrp: Yup.string().required('Actual Price is required'),
  product_discount: Yup.string().required('Discount Price is required'),
  stock_quantity: Yup.string().required('Stock Quantity is required'),
});
const inputProps = {
    step: 1,
    min:1,
};
const pdcCardStyle = {
    border: '1px dashed #000',
    padding: '1rem',
    backgroundColor:'transparent', 
    mb:'1rem'
}

export default function CommonProductForm(props) {
    const {initialValues,isAddForm,successCallback} = props;
    const [pdImg,setPdImg] = useState(null);
    const dispatch = useDispatch();

    const saveProduct = async(values) => {
        if(isAddForm && !pdImg){
            showToast('Please upload product image', 'warning');
        }else{
            let dynPayload = {
                ...values,
                image : pdImg ? pdImg.file : undefined
            };
            if(!isAddForm){
                dynPayload = {
                    ...dynPayload,
                    product_id : values.id,
                    product_url : values.product_link
                }
            }
            const payload = {
                method: 'POST',
                url: isAddForm ? ADMIN_PRODUCT_ADD : ADMIN_PRODUCT_UPDATE,
                data: dynPayload
            };
            try {      
                const res = await AxiIns(payload);
                if(res.status === 200){
                if(res.data.success)
                    {
                        showToast(res.data.success,'success');   
                        resetForm();
                        setPdImg(null);
                        successCallback();
                        if(!isAddForm){dispatch(getProducts())};
                    }     
                else
                    showToast(res.data.error,'error');        
                }
            } catch (error) {
                
            }
        }
    }

    const {handleSubmit,values,handleChange,errors,resetForm} = useFormik({
        initialValues,
        validationSchema,
        onSubmit: values => {
            saveProduct(values);
        },
    });

    const handleUpload = (e) => {
        const fileToUp = e.target.files[0];
        setPdImg({
            file : fileToUp,
            fileUrl : URL.createObjectURL(fileToUp)
        })
    }


    const {
        brand_name = "",
        category_name = "",
        product_name = "",
        product_link = "",
        product_mrp = "",
        product_discount = "",
        stock_quantity = "",
        image = '',
        product_description = "",
        product_image = ''
      } = values;
    // const categories = ['K-Series','Rocket'].map((ele,ind) => <MenuItem key={`cat_${ind}`} value={ele}>{ele}</MenuItem>)  
    return (
        <div>
            <Typography variant="h5" gutterBottom component="div" className='center-text'>{isAddForm ? 'Add New' : 'Update'} Product</Typography>
            <Container maxWidth="md">
                <Card sx={pdcCardStyle}>
                    <CardMedia
                    sx={{ height: 200, backgroundSize: 'contain', mb:'10px' }}
                    image={pdImg ? pdImg.fileUrl :  (product_image || `${process.env.PUBLIC_URL}/images/pdc_placeholder.png`)}
                    title={product_name}
                    />
                <Button className="form-mini-btn common-app-bg" variant="contained" component="label" fullWidth>
                    Upload Image
                    <input hidden accept="image/*" type="file" name="image" value={image} 
                    onChange={handleUpload} />
                </Button>
                </Card>
                <form onSubmit={handleSubmit}>
                    <Stack spacing={2}>
                    <TextField
                    error={errors.brand_name}
                    size="small"
                    id="brand_name"
                    name="brand_name"
                    label="Brand Name"
                    value={brand_name}
                    onChange={handleChange}
                    helperText={errors.brand_name}
                    />
                    <TextField
                    error={errors.product_name}
                    size="small"
                    id="product_name"
                    name="product_name"
                    label="Product Name"
                    value={product_name}
                    onChange={handleChange}
                    helperText={errors.product_name}
                    />
                    <TextField
                    size="small"
                    id="product_description"
                    name="product_description"
                    label="Product Description"
                    value={product_description}
                    onChange={handleChange}
                    multiline
                    rows={3}
                    />
                    <TextField
                    error={errors.product_mrp}
                    size="small"
                    id="product_mrp"
                    name="product_mrp"
                    label="Actual Price"
                    value={product_mrp}
                    onChange={handleChange}
                    helperText={errors.product_mrp}
                    type="number"
                    />
                    <TextField
                    error={errors.product_discount}
                    size="small"
                    id="product_discount"
                    name="product_discount"
                    label="Discount Price"
                    value={product_discount}
                    onChange={handleChange}
                    helperText={errors.product_discount}
                    type="number"
                    />
                    <TextField
                    error={errors.category_name}
                    size="small"
                    id="category_name"
                    name="category_name"
                    label="Category"
                    value={category_name}
                    onChange={handleChange}
                    helperText={errors.category_name}
                    />
                    {/* <FormControl sx={{ m: 1, minWidth: 120 }} error={errors.category_name} size="small">
                    <InputLabel id="category_name-label">Category</InputLabel>
                    <Select
                    labelId="category_name-label"
                    id="category_name"
                    value={category_name}
                    label="Category"
                    onChange={handleChange}
                    name='category_name'
                    >
                    <MenuItem value="">
                        <em>Select</em>
                    </MenuItem>
                    {categories}
                    </Select>
                    <FormHelperText>{errors.category_name}</FormHelperText>
                </FormControl> */}
                <TextField
                    error={errors.product_link}
                    size="small"
                    id="product_link"
                    name="product_link"
                    label="Product URL"
                    value={product_link}
                    onChange={handleChange}
                    helperText={errors.product_link}
                    />
                    <TextField
                    error={errors.stock_quantity}
                    size="small"
                    id="stock_quantity"
                    name="stock_quantity"
                    label="Stock Quantity"
                    value={stock_quantity}
                    onChange={handleChange}
                    helperText={errors.stock_quantity}
                    type="number"
                    inputProps={inputProps}
                    />
                    
                    {/* <TextField
                    fullWidth
                    label="Description"
                    id="outlined-size-small"
                    size="small"
                    /> */}
                
                    
                    <center><Button className="form-mini-btn common-app-bg" variant="contained" size="small" type='submit'>
                    {isAddForm ? 'Save' : 'Update'} Product
                    </Button></center>
                    </Stack>
                </form>
            </Container>
        </div>
    )
}
