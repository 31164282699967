import React, { useLayoutEffect, useState } from 'react'
import { Box, Grid, Tab, Tabs, Typography } from '@mui/material'
import PropTypes from 'prop-types';
import MyOrders from './MyOrders';

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}
const tabList = ['My Orders'];
export default function MyAccount() {
  const [value, setValue] = useState(0);
  const [isMobileView, setIsMobileView] = useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleResize = () => {    
    const mobileDeviceWid = window.innerWidth < 769
    setIsMobileView(mobileDeviceWid)
  }

  useLayoutEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize',handleResize)
    };
  }, [])

  return (
    <div className='dynamic-content'>        
        <Box
      sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex', height: 'auto', mt:'3.4rem' }}
    > 
    <Grid 
      container 
      spacing={{ xs: 1, md: 1 }} 
      direction="row"
      // justifyContent="center"
      // alignItems="center"
      >  
        <Grid item xs={isMobileView ? 12 : 2}>  
            <Tabs
              // centered
              variant="scrollable"
              scrollButtons
              allowScrollButtonsMobile
              orientation={isMobileView ? "horizontal" : "vertical"}
              value={value}
              onChange={handleChange}
              aria-label="User Menues"
              sx={{ borderRight: 1, borderColor: 'divider', width:'100%',height:'100%', backgroundColor:'#5a9dec0f' }}
            >
              {tabList.map((record,index) => 
                  <Tab label={record} {...a11yProps(index)} sx={{fontSize:'10px'}} key={`tab_key_${index}`} />
              )}
            </Tabs>
        </Grid>
        <Grid item xs={isMobileView ? 12 : 10}>
            {/* <TabPanel value={value} index={0} key={`my_account`}>
              
            </TabPanel> */}
            <TabPanel value={value} index={0} key={`my_orders`}>
              <MyOrders />
            </TabPanel>
        </Grid>
      </Grid>
    </Box>    
    </div>
  )
}
