import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import CssBaseline from '@mui/material/CssBaseline';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import {Link, useNavigate } from 'react-router-dom';
import { CUSTOMER_ACCOUNT, CUSTOMER_CART, CUSTOMER_INITIAL } from '../../../helpers/constants/RoutePaths';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import Badge from '@mui/material/Badge';
import { useState } from 'react';
import { useEffect } from 'react';
import { authCustomer, isMobileScreen } from '../../../helpers/functions/CustomFunctions';
import MenuIcon from '@mui/icons-material/Menu';
import { useSelector } from 'react-redux';
import { Menu, MenuItem, Typography } from '@mui/material';
import { AUTH_CUSTOMER } from '../../../helpers/constants/LocalStorage';

function ElevationScroll(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}

export default function StickyNavBar(props) {
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const navigate = useNavigate();
  const {handleDialogFlag,handleSearchToggleFlag, toggleDrawer,mainMenues} = props;

  const [isTransBg,setTransBg] = useState(true);
  const handleAccountMenu = (type) => {
    switch(type){
      case 'profile':
        navigate(CUSTOMER_ACCOUNT);
        break
      case 'logout':
        localStorage.removeItem(AUTH_CUSTOMER);
        navigate(CUSTOMER_INITIAL);
        break;
      default:
        break;
    }
    setAnchorElUser(null);
  }
  const handleProfileClick = (event) => {
    if(authCustomer())
    setAnchorElUser(event.currentTarget);    
    else
    handleDialogFlag()
  };
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const handleClick = () => {
    navigate(CUSTOMER_CART)
  }

  const handlePageScroll = () => {
    if(window.scrollY === 0) 
      setTransBg(true)
    else setTransBg(false)
  }

  const goToHome = () => {
    navigate(CUSTOMER_INITIAL)
  }  
 
  useEffect(() => {
    window.addEventListener('scroll',handlePageScroll)
  },[]);
  
  const {items = []} = useSelector((state) => state.cartItems);
  return (
    <React.Fragment>
      <CssBaseline />
      <ElevationScroll {...props}>
        <AppBar className='app-header'>
          <Toolbar disableGutters={true} variant={'dense'}  className="app-toolbar"
          sx={{
            '& .MuiToolbar-root': {
              minHeight: 'auto !important',
            },
          }}>
            <div className={`logo-block ${isTransBg ? 'trans-logo-block' : 'white-bg'}`} onClick={goToHome}>
                <img src="/images/logowithtext.png" alt="Crackers Mart" width={isMobileScreen() ? 100 : 100} />
            </div>
            <div className={`nav-menues-block ${isTransBg ? 'trans-nav-menu-block' : 'primary-nav-menu-block'}`}>
                <div className="between-space" id="mob-menu-top-head">
                  <h4>Crackers Mart</h4>
                  <IconButton aria-label="delete" className="bar-icon" onClick={toggleDrawer}>
                    <MenuIcon />
                  </IconButton>
                </div>
                <div className='nav-menu-sections'>                    
                    <div className="menu-section">
                        <ul>
                          {mainMenues.map(({title,link},index) =>
                            <li key={`menu_top_${index}`}><Link className='main-menu' to={link}>{title}</Link></li>
                          )}
                        </ul>
                    </div>
                    <div className="hor-ver-center">
                        {/* <IconButton aria-label="delete" size="small" onClick={handleSearchToggleFlag}>
                        <SearchIcon className='main-icons'/>
                        </IconButton> */}
                        <IconButton aria-label="delete" size="small" onClick={handleProfileClick}>
                        <AccountCircleOutlinedIcon className='main-icons'/>
                        </IconButton>
                          <Menu
                            sx={{ mt: '25px' }}
                            id="menu-appbar"
                            anchorEl={anchorElUser}
                            anchorOrigin={{
                              vertical: 'top',
                              horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                              vertical: 'top',
                              horizontal: 'right',
                            }}
                            open={Boolean(anchorElUser)}
                            onClose={handleCloseUserMenu}
                            >
                              <MenuItem key="my_account" onClick={() => {handleAccountMenu('profile')}}>
                                <Typography textAlign="center">My Account</Typography>
                              </MenuItem>
                              <MenuItem key="logout" onClick={() => {handleAccountMenu('logout')}}>
                                <Typography textAlign="center">Log Out</Typography>
                              </MenuItem>
                          </Menu>
                        <IconButton
                            size="small"
                            aria-label="show 17 new notifications" onClick={handleClick}>
                          <Badge badgeContent={items.length} color="secondary" className='cart-count'>
                              <ShoppingCartIcon className='main-icons' />
                          </Badge>
                        </IconButton> 
                    </div>
                </div>
            </div>            
          </Toolbar>
        </AppBar>
        
      </ElevationScroll>
      <Toolbar />    
    </React.Fragment>
  );
}
