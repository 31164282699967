export const UPI_ID = '8870301420@PAYTM';
export const WHATS_APP_NUMBER = "88 70 30 14 20";
export const MINIMUM_ORDER_VALUE="1500";
export const PACKAGING_CHARGE_PERCENTAGE = 3;
export const REVIEWS = [{
    name:'Raja Sundaravel',
    review : 5,
    msg:"Super guys.👏 nice crackers. Explosive 🧨 had an amazing Diwali 🎇Whoever require crackers buy here. Crackers are good at reasonable price."
},{
    name:'Rahul Nair',
    review: 5,
    msg: "This was my first time buying from SK. The crackers were of very good quality. The delivery was on time and the rate was also cheap. I would definitely recommend this."
},{
    name:'Thiyaga Rajan',
    review: 5,
    msg: "Sk crackers super and all crackers wonderful ek specially that peacock crackers it very amazing and I will be wonderful I and all wala  Dulux all will be a wonderful the sound quality and I worth for money l Love it thanks for sk crackers I enjoyed my diwali with your crackers to well all the best."
},{
    name:'karunakaran thirunavukarasu',
    review: 5,
    msg: "Crackers are so good. Lot of varieties.. very unique products.  We are very much satisfied."
},{
    name:'Siva kumar',
    review: 5,
    msg: "Very good response Diwali collection the best sk crackers"
},{
    name:'Arun Prabhu',
    review: 5,
    msg: "Worth Buying...!! Quality Products...!! Best Price..…!!"
},{
    name:'Santhos kumar',
    review: 5,
    msg: "So many varieties, reasonable price, highly recommend ❤️❤️❤️"
},{
    name:'siddu kutty',
    review: 5,
    msg: "Supper crackers 👏💥 "
},{
    name:'Sudheer Koppula',
    review: 5,
    msg: ""
},{
    name:'Keerthi Sundaram',
    review: 5,
    msg: ""
}];