import { createSlice } from '@reduxjs/toolkit';

export const CustomerOrdersReducer = createSlice({ 
    name:"customerOrders",
    initialState: {
        list: [],
        loading: false
    },
    reducers: {
        setCustomerOrdersList: (state,action) => { 
            const { payload } = action;
            state.list = payload;
        }
    } 
});

export const {setCustomerOrdersList  } = CustomerOrdersReducer.actions;
export default CustomerOrdersReducer.reducer; 