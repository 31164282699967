import React from 'react'
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import { styled } from '@mui/material/styles';

const CustomIconButton= styled(IconButton)(({ theme }) => ({
    backgroundColor: '#e31e24 !important', 
    color: '#fff !important',    
}));

export default function CustomDeleteButton({handleClick}) {
  return (
    <CustomIconButton aria-label="delete" size="small" onClick={handleClick}>
      <DeleteIcon fontSize="inherit" />
    </CustomIconButton>
  )
}
