import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import CustomTableHeader from '../common/CustomTableHeader';
import CustomDeleteButton from '../common/CustomDeleteButton';
import {useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addItemToCart, removeItemFromCart } from '../../redux/actions';
import { showToast } from '../../helpers/functions/CustomFunctions';
import { useDebounce } from 'use-debounce';
import { useMemo } from 'react';

const columns = ['Product Name','Unit Price','Quantity','Sub Total','Actions']
const inputProps = {
  step: 1,
  min:1,
  style:{
      height:'17px', 
      width:'80px'
  }
};

const rows = [
  {name : 'Red/ Green/ Gold/ White Peacock', price : '480'},
  {name : 'Red/ Green/ Gold/ White Peacock', price : '480'},
  {name : 'Red/ Green/ Gold/ White Peacock', price : '480'},
  {name : 'Red/ Green/ Gold/ White Peacock', price : '480'},
  {name : 'Red/ Green/ Gold/ White Peacock', price : '480'},

  {name : 'Red/ Green/ Gold/ White Peacock', price : '480'},
  {name : 'Red/ Green/ Gold/ White Peacock', price : '480'},
  {name : 'Red/ Green/ Gold/ White Peacock', price : '480'},
  {name : 'Red/ Green/ Gold/ White Peacock', price : '480'},
  {name : 'Red/ Green/ Gold/ White Peacock', price : '480'},
  
  {name : 'Red/ Green/ Gold/ White Peacock', price : '480'},
];


const CartQuantityInput = ({quantity,stock_quantity,product_id}) => {
  const [newQty,setNewQty] = useState(quantity);
  const dispatch = useDispatch(); 
  const [value] = useDebounce(newQty, 1500);
  useMemo(async() => {
    if(value && parseInt(value) > 0 && parseInt(quantity) !== parseInt(value)){
      const formData = {
        product_id,
        quantity:value
      }
      const isUpdated = await dispatch(addItemToCart(formData));
      if(!isUpdated){
        setNewQty(quantity)
      }
    }
  }, [value])
  const handleChange = (ev) => {
    const {value} = ev.target;
    setNewQty(value)
  }
  return(
    <TextField id="outlined-basic" variant="outlined" step="1" type={'number'} size="small" inputProps={inputProps} value={newQty} onChange={handleChange} onWheel={(e) => e.target.blur()}/>  
  )
}
export default function CartProducts() {
  const {items = []} = useSelector((state) => state.cartItems);

  const dispatch = useDispatch();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10); 

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleDelete = (id) => {
    const formData = {
      cart_id : id
    };
    dispatch(removeItemFromCart(formData));
  }  

  
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
        <CustomTableHeader columns={columns } />
        <TableBody>
          {items.map(({id,product_id,product_name,quantity,stock_quantity,product_discount,product_bill},index) => (
            <TableRow
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              key={`row_${index}`}
            >
              <TableCell component="th" scope="row">
              {product_name}
              </TableCell>
              <TableCell>
              &#8377;{product_discount}
              </TableCell>
              <TableCell>
                <CartQuantityInput quantity={quantity} stock_quantity={stock_quantity} product_id={product_id}/>
              </TableCell>
              <TableCell>
              &#8377;{product_bill}
              </TableCell>
              <TableCell>
              <CustomDeleteButton handleClick={() => handleDelete(id)} />
              </TableCell>
            </TableRow>
            ))}
        </TableBody>
        <TablePagination 
            rowsPerPageOptions={[10, 50]} 
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            SelectProps={{
              inputProps: {
                'aria-label': 'rows per page',
              },
              native: true,
            }}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
      </Table>
    </TableContainer>
  );
}
