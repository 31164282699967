import { configureStore } from '@reduxjs/toolkit' 
import AuthDialogReducer from '../reducers/AuthenticationDialogReducer'
import OrdersReducer from '../reducers/ShowAllOrdersReducer'
import CartItemsReducer from '../reducers/GetCartItemsReducer'
import CustomerOrdersReducer from '../reducers/GetCustomerOrdersReducer'
import AuthCustomerSlice from '../reducers/AuthenticateCustomerReducer'
import ProductsReducer from '../reducers/GetProductsReducer'
export const store =  configureStore({ 
    reducer: {
        authDialog:  AuthDialogReducer,  
        orders: OrdersReducer ,
        cartItems: CartItemsReducer  ,
        customerOrders: CustomerOrdersReducer   ,
        authCustomer: AuthCustomerSlice,
        productsList: ProductsReducer
    } //add reducers here 
}) 
