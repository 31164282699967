import React from 'react'
import { useFormik } from 'formik';
import { Box, TextField,Button, InputAdornment } from '@mui/material';
import * as Yup from 'yup';
import { SIGNUP_API } from '../../../helpers/constants/ApiPaths';
import AxiIns from '../../../helpers/API';
import { showToast } from '../../../helpers/functions/CustomFunctions';
const initialValues = {
    username:'',
    name:'',
    date_of_birth:'',    
    email_id:'',
    phone_number:'',
    password:'',
}

const validationSchema = Yup.object({
    username : Yup.string().required('User name is required'),
    name : Yup.string().required('Name is required'),
    date_of_birth: Yup.string().required('Date of birth is required'),
    email_id: Yup.string().required('Email address is required'),
    phone_number: Yup.string().matches(/^\d{10}$/,'Enter a valid 10 digit phone number').required('Phone number is required'),
    password: Yup.string().min(8,'Password must contain at least 8 characters').required('Password is required'),    
});

export default function SignUp({handleAuthTypeToggle}) {
    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: values => {
          handleSignup(values);
        },
    });
    const {handleSubmit,values,handleChange,errors} = formik;
    const handleSignup = async(values) => {
        const payload = {
            method: 'POST',
            url: SIGNUP_API,
            data: values
        };
        try {      
        const res = await AxiIns(payload);
        if(res.status === 200){
            if(res.data.success){
                showToast(res.data.success,'success');    
                formik.resetForm();
                handleAuthTypeToggle();
            }    
            else
            showToast(res.data.error,'error');        
        }
        }catch (error) {
            
        }
    }

    const {
        username = '',
        name='',
        date_of_birth='',    
        email_id='',
        phone_number='',
        password='',
    } = values;
    return (
        <>
        <p><b>Sign Up</b></p>
        <form onSubmit={handleSubmit}>
            <Box>
            <TextField
                fullWidth
                margin="normal"
                error={errors.username}
                size="small"
                id="username"
                name="username"
                label="User Name"
                value={username}
                onChange={handleChange}
                helperText={errors.username}
            />
            <TextField
                fullWidth
                margin="normal"
                error={errors.name}
                size="small"
                id="name"
                name="name"
                label="Name"
                value={name}
                onChange={handleChange}
                helperText={errors.name}
            />
            <TextField
                fullWidth
                margin="normal"
                error={errors.date_of_birth}
                size="small"
                type="date"
                id="date_of_birth"
                name="date_of_birth"
                // placeholder="Date of Birth"
                // label="Date of birth"
                value={date_of_birth}
                onChange={handleChange}
                helperText={errors.date_of_birth}
            />
            <TextField
                fullWidth
                margin="normal"
                error={errors.email_id}
                size="small"
                id="email_id"
                name="email_id"
                label="Email Address"
                value={email_id}
                onChange={handleChange}
                helperText={errors.email_id || "We'll never share your email with anyone else."}
            />
            <TextField
                InputProps={{
                    startAdornment: <InputAdornment position="start">+91</InputAdornment>,
                }}
                fullWidth
                margin="normal"
                error={errors.phone_number}
                size="small"
                id="phone_number"
                name="phone_number"
                label="Phone Number"
                value={phone_number}
                onChange={handleChange}
                helperText={errors.phone_number || "We'll never share your phone number with anyone else."}
            />
            <TextField
                fullWidth
                margin="normal"
                error={errors.password}
                size="small"
                type="password"
                id="password"
                name="password"
                label="Password"
                value={password}
                onChange={handleChange}
                helperText={errors.password}
            />      
            <Button
            type="submit"
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            className="common-app-bg"
            size="small"
            >
            Sign Up
            </Button>    
            <p>Already have an account? Please <Button variant="text" size="small" onClick={handleAuthTypeToggle}>Sign In</Button></p>   
        </Box>
        </form>
        </>
    )
}
