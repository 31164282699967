import * as React from 'react';
import {Fragment} from 'react'
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import CallReceivedIcon from '@mui/icons-material/CallReceived';
import PendingIcon from '@mui/icons-material/Pending';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import CancelIcon from '@mui/icons-material/Cancel';
import Divider from '@mui/material/Divider';
import {ORDERS_RECEIVED,PAYMENT_PENDING,DISPATCH_PENDING,COMPLETED_ORDERS,CANCELLED_ORDERS,ADD_PRODUCT,UPDATE_PRODUCT} from '../../../helpers/constants/RoutePaths'
import {useNavigate} from 'react-router-dom'
import { styled } from '@mui/material/styles';

const orderMenues = [
  {
    label : "Orders Received",
    icon : <CallReceivedIcon />,
    path : ORDERS_RECEIVED,
  },
  {
    label : "Payment Pending",
    icon : <PendingIcon />,
    path : PAYMENT_PENDING,
  },
  {
    label : "Dispatch Pending",
    icon : <LocalShippingIcon />,
    path : DISPATCH_PENDING,
  },
  {
    label : "Completed Orders",
    icon : <DoneAllIcon />,
    path : COMPLETED_ORDERS,
  },
  {
    label : "Cancelled Orders",
    icon : <CancelIcon />,
    path : CANCELLED_ORDERS,
  }
];
const productMenues = [
  {
    label : "Add Product",
    icon : <AddIcon />,
    path : ADD_PRODUCT,
  },
  {
    label : "Update Product",
    icon : <EditIcon />,
    path : UPDATE_PRODUCT,
  }
];

const CustomListItemButton = styled(ListItemButton)(({ theme }) => ({  
  "&.Mui-selected": {
    backgroundColor: '#e31e24',
    color:'#fff',  
  },
  "&.Mui-selected:hover": {
    backgroundColor: "#e31e24",
    color:'#fff',   
  },
  '&:hover': {
    backgroundColor: '#f26b6f',
    color:'#fff',    
  },
}));

export default function MenuItems() {
  const navigate = useNavigate();
  const activeMenu = window.location.pathname.split('/')[2] || '/';

  const handleMenuClick = (path) => {
    navigate(path);
  }
  return(
    <Fragment>
      <ListSubheader component="div" inset>
      Orders
    </ListSubheader>
    {orderMenues.map(({label,icon,path},index) => 
    <CustomListItemButton key={`ord_${index}`} onClick={() => handleMenuClick(path)} selected={activeMenu === path.split('/')[2]}>
      <ListItemIcon className='iconStyle'>
        {icon}
      </ListItemIcon>
      <ListItemText primary={label} />
    </CustomListItemButton>
    )}    
      <Divider sx={{ my: 1 }} />
      <ListSubheader component="div" inset>
      Products
    </ListSubheader>
    {productMenues.map(({label,icon,path},index) => 
    <CustomListItemButton key={`pdc_${index}`} onClick={() => handleMenuClick(path)} selected={activeMenu === path.split('/')[2]}>
      <ListItemIcon className='iconStyle'>
        {icon}
      </ListItemIcon>
      <ListItemText primary={label} />
    </CustomListItemButton>
    )}      
    </Fragment>
  );
}


