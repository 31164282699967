import React from 'react'
import Grid from '@mui/material/Grid';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import YouTubeIcon from '@mui/icons-material/YouTube';
import TwitterIcon from '@mui/icons-material/Twitter';
import GoogleIcon from '@mui/icons-material/Google';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import { CUSTOMER_PAYMENT, CUSTOMER_SHIPPING_POLICY, CUSTOMER_TESTIMONIALS,CUSTOMER_SHOP } from '../../../helpers/constants/RoutePaths';
import { Link } from 'react-router-dom';

export default function Footer({handleDialogFlag}) {
    const handleLink = (event) => {
        const {link} = event.currentTarget.dataset;
        window.open(link,'_blank')
    }
  return (
    <Box        
        component="footer"
        id="footer-sec"
        >
      <Grid 
        container 
        spacing={{ xs: 2, md: 4 }} 
        direction="row"
        justifyContent="center"
        // alignItems="center"
        id="footer-main-sec"
        >
          <Grid item xs={12} sm={4} md={3} lg={3}>
            <div id="footer-app-info">
              <center><img src='/images/footer_logo.png' alt="Crackers Mart" width="150"/></center>
              <p>CRACKERSMART is a leading crackers online shopping website in Sivakasi, India. Buy crackers from the original branded Sivakasi crackers and selling in Mumbai, Indore, Pune, Hyderabad, Delhi and all over India using <a href="http://crackersmart.in/" target="_blank" rel='noreferrer'>crackersmart.in</a>. All modern fireworks are available for all ages starting from Chakra to Sky shots, Fountains to Bombs. Buy fireworks online, just a click to our website and enjoy this eve with your friends and family with our best quality crackers at affordable prices. We, Crackersmart, offers delivery in all major locations.</p>
            </div>
          </Grid>
          <Grid item xs={12} sm={3} md={2} lg={2}>
            <div className="footer-menu-sec">
            <Typography variant="h6" gutterBottom>Help & Customer Support</Typography>
              <ul>
                <li onClick={handleDialogFlag}>New User? Sign Up</li>
                <li onClick={handleDialogFlag}>My Account</li>
                <li><Link to={CUSTOMER_SHIPPING_POLICY}>Shipping Policy</Link></li>
                <li><Link to={CUSTOMER_PAYMENT}>Payment Policy</Link></li>
                <li><Link to={CUSTOMER_TESTIMONIALS}>Testimonials</Link></li>
              </ul>
            </div>
          </Grid>
          <Grid item xs={12} sm={3} md={2} lg={2}>
            <div className="footer-menu-sec">
            <Typography variant="h6" gutterBottom>Shop By Categories</Typography>
              <ul>
                <li><Link to={`${CUSTOMER_SHOP}/Lakshmi Crackers`}>Lakshmi Crackers</Link></li>
                <li><Link to={`${CUSTOMER_SHOP}/K-Series`}>K-Series</Link></li>
                <li><Link to={`${CUSTOMER_SHOP}/EDC-Series`}>EDC-Series</Link></li>
                <li><Link to={`${CUSTOMER_SHOP}/Bijili`}>Bijili</Link></li>
                <li><Link to={`${CUSTOMER_SHOP}/Ground Chakkars`}>Ground Chakkars</Link></li>
              </ul>
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <div className="mapouter">
              <div className="gmap_canvas">
              <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3935.739053567718!2d77.79789459999999!3d9.4442666!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3b06cf122d01f5d3%3A0xf107e6bf8c6cd5f4!2sSK%20Crackers!5e0!3m2!1sen!2sin!4v1697278987858!5m2!1sen!2sin" width="600" height="360" style={{border:'0'}} title='SK Crackers' allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
              </div>
            </div>
            <div>
            {/* sales@crackersmart.in  */}
            {/* For Immediate response contact:  +91 88 70 30 14 20 */}             
            </div>
          </Grid>
          
      </Grid>
      <Grid 
        container 
        spacing={{ xs: 2, md: 2 }} 
        direction="row"
        justifyContent="center"
        alignItems="center"
        >
          <Grid item xs={12} sm={6} md={7} lg={6}>
            <div id="copyright-sec">
              <Typography variant="body2" color="text.secondary" align="center">
                Copyright © 2020 reserved by <b>CrackersMart</b> Developed by <a rel='noreferrer' color="inherit" href="https://wa.me/8870301420?text=Welcome%20to%20The%203rd%20i%20infotech%2C%20the%20Best%20place%20to%20grow%20your%20business%20in%20the%20digital%20world.%20" target={'_blank'}><b>The 3<sup>rd</sup> i infotech</b></a>
                {'.'}
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} sm={6} md={5} lg={6}>           
              <div className="center-text p-1r">
              <IconButton className="social-icons" data-link="https://www.instagram.com/sk_crackers" onClick={handleLink} aria-label="delete" size="small">
                <InstagramIcon fontSize="inherit" />
              </IconButton>
              <IconButton className="social-icons" data-link="https://www.facebook.com/skcrackers" onClick={handleLink} aria-label="delete" size="small">
                <FacebookIcon fontSize="inherit" />
              </IconButton>
              <IconButton className="social-icons" data-link="https://www.instagram.com/sk_crackers" onClick={handleLink} aria-label="delete" size="small">
                <YouTubeIcon fontSize="inherit" />
              </IconButton>
              <IconButton className="social-icons" data-link="" onClick={handleLink} aria-label="delete" size="small">
                <TwitterIcon fontSize="inherit" />
              </IconButton>
              <IconButton className="social-icons" data-link="https://www.google.com/maps/place/SK+Crackers/@9.44424,77.797887,14z/data=!4m5!3m4!1s0x0:0xf107e6bf8c6cd5f4!8m2!3d9.444279!4d77.7978981?hl=en" onClick={handleLink} aria-label="delete" size="small">
                <GoogleIcon fontSize="inherit" />
              </IconButton>
              </div>
          </Grid>
        </Grid>
      
      </Box>
  )
}
