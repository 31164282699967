import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useState, useLayoutEffect } from 'react';
import Alert from '@mui/material/Alert';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import ProductCard from '../common/ProductCard';
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
import Pagination from '@mui/material/Pagination';
import { CATEGORY_FILTER } from '../../helpers/constants/ApiPaths';
import AxiIns from '../../helpers/API';
import NoProductFound from '../common/NoProductFound';
import { scrollToTop } from '../../helpers/functions/CustomFunctions';
import { Button } from '@mui/material';
import "./CategoryWiseProducts.scss";
function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
const Item = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
}));

const CustomAlert = styled(Alert)(({ theme }) => ({
    textAlign:'center !important',
    display:'block !important',
    marginTop:'1rem',
    fontWeight:'bold',
    fontSize:'18px',
    padding:'10px',
    border: '1px solid #ccced0',
    background:'none'
}));

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const categoriesList = ['Lakshmi Crackers','K-Series','EDC-Series','Bijili','Ground Chakkars','Flower Pots','Bombs','Rocket','Sparklers','Fancy','Fountains','Repeating Shots','Twinkling Stars & Candles','Combos']

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

export default function CategoryWiseProductsTab() {
  const [records, setRecords] = useState([]);
  const perPage = 10;
  const totalpages = Math.ceil(records.length / perPage);

  const {category = ''} = useParams()
  const [value, setValue] = useState(0);
  const [page, setPage] = useState(1);
  const [isMobileView, setIsMobileView] = useState(false);
  const ref = React.useRef(null);

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const commonCatChange = (newValue) => {
    getCatBasedProducts(categoriesList[newValue]);  
    setValue(newValue);  
    setPage(1)
  }
  const handleChange = (event, newValue) => {
    commonCatChange(newValue);
    
  };

  const handleResize = () => {    
    const mobileDeviceWid = window.innerWidth < 769
    setIsMobileView(mobileDeviceWid)
  }

  const getCatBasedProducts = async (category) => {
      try{
        const payload = {
            method: 'POST',
            url: CATEGORY_FILTER,
            params:{
              category
            }
        };
        const res = await AxiIns(payload);
        if(res.status === 200){
            if(res.data.status === 'success') 
            setRecords(res.data.data) 
        }
      }catch(error){

      }
      finally{
          //setLoading(false)
      } 
  }
  const handleSuccessCallback = () => {
    getCatBasedProducts(categoriesList[value]);
  }

  useEffect(() => {
    let catIndex = 0;
    if(category){
      catIndex = categoriesList.findIndex((element) => element.toLowerCase() === category.toLowerCase())
    }
    getCatBasedProducts(categoriesList[catIndex]);
    setValue(catIndex)
  },[category]);

  useEffect(() => {
    scrollToTop();
  },[category,value])

  useLayoutEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize',handleResize)
    };
  }, []);

  // useEffect(() => {
  //   const element = ref.current;
  //   element.addEventListener("scroll", () => {
  //     console.log("element..",element.scrollHeight,element.scrollTop,element.clientHeight);
  //     if ((element.scrollTop + element.clientHeight) >= element.scrollHeight - 3 ) {
  //       console.log('in')
  //       setValue(prevVal => prevVal + 1);
  //       setPage(1)
  //       // The user has scrolled to the bottom of the page.
  //     }
  //   });
  // }, []);
  const getPrevious = () => {
    return categoriesList[value - 1];
  }
  const getNext = () => {
    return categoriesList[value + 1];
  }
  const goToPrevious = () => {
    commonCatChange(value - 1)
  }
  const goToNext = () => {
    commonCatChange(value + 1)
  }

  const startFrom = (page - 1) * perPage;
  const end = (page * perPage)
  const currentRecords = records.slice(startFrom,end);
  return (
    <Box
      sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex', height: 'auto' }}
    > 
    <Grid 
      container 
      spacing={{ xs: 1, md: 1 }} 
      direction="row"
      // justifyContent="center"
      // alignItems="center"
      >  
        <Grid item xs={isMobileView ? 12 : 2}>  
            <Tabs
              // centered
              variant="scrollable"
              scrollButtons
              allowScrollButtonsMobile
              orientation={isMobileView ? "horizontal" : "vertical"}
              value={value}
              onChange={handleChange}
              aria-label="Categories"
              sx={{ borderRight: 1, borderColor: 'divider', width:'100%',height:'100%', backgroundColor:'#5a9dec0f' }}
            >
              {categoriesList.map((record,index) => 
                  <Tab label={record} {...a11yProps(index)} sx={{fontSize:'10px'}} key={`tab_key_${index}`} />
              )}
            </Tabs>
        </Grid>
        <Grid item xs={isMobileView ? 12 : 10}>
          {categoriesList.map((cat,catInd) => 
            <TabPanel value={value} index={catInd} key={`cat_tab_data_${catInd}`}>
              <div>
              <CustomAlert icon={false} severity="info" >{cat}</CustomAlert>
              <Grid 
                ref={ref}
                container 
                // spacing={{ xs: 2, md: 3 }} 
                direction="row"
                // justifyContent="center"
                // alignItems="center"
                // className="scrollable-sec"
                > 
                  {currentRecords.length ? currentRecords.map((ele,index) =>
                    <Grid item xs={12} sm={6} md={4} lg={3} key={`pdc_${index}`}>
                      <ProductCard detail={ele} key={`pdc_${index}`} successCallback={handleSuccessCallback}/>
                    </Grid>                    
                  ) : 
                    <Grid item xs={12}>
                      <NoProductFound />
                    </Grid>
                  }  
                  {
                  (currentRecords.length > 0 && totalpages > 1) &&
                  <Grid 
                      container
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                      > 
                      <Grid item xs={12}>
                        <Item>
                        <Pagination count={totalpages} page={page} onChange={handlePageChange}/>
                        </Item>
                        </Grid>
                    </Grid>  
                  }        
                </Grid>
                
              </div>
              <div className='navi-sec'>
                <div>
                 {value > 0 ? <Button onClick={goToPrevious}><small>{'<'}</small>{getPrevious()}</Button> : ''}
                </div>
                <div>
                  {(value !== categoriesList.length - 1) ? <Button onClick={goToNext}>{getNext()}<small>{'>'}</small></Button> : ''}
                </div>
              </div>
            </TabPanel>
          )}
        </Grid>
      </Grid>
    </Box>
  );
}
