import React, { useEffect, useState } from 'react'
import HowToOrder from './HowToOrder'
import Grid from '@mui/material/Grid';
import ProductCard from '../common/ProductCard';
import Alert from '@mui/material/Alert';
import { styled } from '@mui/material/styles';
import CategoryWiseProductsTab from './CategoryWiseProductsTab';
import { getFilteredProducts } from '../../redux/actions';

const CustomAlert = styled(Alert)(({ theme }) => ({
    textAlign:'center !important',
    display:'block !important',
    marginTop:'1rem',
    fontWeight:'bold',
    fontSize:'18px',
    padding:'10px',
    border: '1px solid #ccced0',
    background:'none'
}));

export default function NewArrivals() {
  const [list,setList] = useState([]);   
    
  const getProducts = async() => {     
   const data = await getFilteredProducts('new_arrival');  
   setList(data);   
  }
  const handleSuccessCallback = () => {
    getProducts();
  }
  useEffect(() => {
    getProducts();
  },[])
  return (
    <div className='dynamic-content'>        
        <HowToOrder/>      
        <div>
        <CustomAlert icon={false} severity="info" >NEW ARRIVALS</CustomAlert>
        <Grid 
          container 
          // spacing={{ xs: 2, md: 2 }} 
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
          > 
            {list.map((ele,index) =>
              <Grid item xs={12} sm={6} md={4} lg={3} xl={2} key={`pdc_${index}`}>
                <ProductCard detail={ele} key={`pdc_${index}`} successCallback={handleSuccessCallback}/>
                </Grid>
            )}
            </Grid>
        </div>      
    </div>
  )
}
