import { Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import CustomPageLoading from '../common/CustomPageLoading';
import { getCustomerOrders, getOrders } from './../../redux/actions/index';
import { MY_ORDERS } from '../../helpers/constants/ApiPaths';
import { authCustomer } from '../../helpers/functions/CustomFunctions';
import AxiIns from '../../helpers/API';
import OrdersReceivedList from '../common/OrdersReceivedList';
import NoRecordFound from '../common/NoRecordFound';
import { useDispatch, useSelector } from 'react-redux';

export default function MyOrders() {
  const dispatch = useDispatch();
  // const [loading,setLoading] = useState(false);
  const {list = [],loading = true} = useSelector(state => state.customerOrders);

  // const getOrders = async() => {
  //   try{
  //     setLoading(true)
  //     const {id} = authCustomer();
  //     const payload = {
  //         method: 'GET',
  //         url: MY_ORDERS,
  //         params: {
  //           user_id : id
  //         }              
  //     };
  //     const res = await AxiIns(payload);
  //     if(res.status === 200){
  //         if(res.data.status === 'success') 
  //         setList(res.data.data) 
  //     }
  //   }catch(error){

  //   }
  //   finally{
  //       setLoading(false)
  //   } 
  // }
  useEffect(() => {  
    dispatch(getCustomerOrders())
  }, []);  

  return (
    <div>
      <CustomPageLoading flag={loading} />
      <Typography variant="h5" gutterBottom component="div">My Orders</Typography>     
      {!loading ?
        !list.length ?
          <>
          <NoRecordFound />
          </>
        :
          list.map((record,index) => 
          <OrdersReceivedList key={`ord_rcv_${index}`} record={record} statusType="all-orders"/>
          )
      :''
      }
    </div>
  )
}
