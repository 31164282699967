import React from 'react'
import { SIGNIN_API } from '../../../helpers/constants/ApiPaths';
import AxiIns from '../../../helpers/API';
import { useFormik } from 'formik';
import { Box, TextField,Button } from '@mui/material';
import * as Yup from 'yup';
import { showToast } from '../../../helpers/functions/CustomFunctions';
import { useDispatch } from 'react-redux';
import { dialogToggle } from '../../../redux/actions/reducers/AuthenticationDialogReducer';
import { AUTH_CUSTOMER } from '../../../helpers/constants/LocalStorage';
import { getCartItems } from '../../../redux/actions';
import { setAuthFlag } from '../../../redux/actions/reducers/AuthenticateCustomerReducer';

const initialValues = {
    email_id:'',
    password:'',
}
const validationSchema = Yup.object({    
    email_id: Yup.string().required('Email address is required'),
    password: Yup.string().required('Password is required'),    
});
export default function SignIn({handleAuthTypeToggle}) {
    const dispatch = useDispatch();
    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: values => {
          handleSignin(values);
        },
    });
    const {handleSubmit,values,handleChange,errors} = formik;
    const handleSignin = async(values) => {
        const payload = {
            method: 'POST',
            url: SIGNIN_API,
            data: values
        };
        try {      
        const res = await AxiIns(payload);
        if(res.status === 200){
            if(res.data.success){
                localStorage.setItem(AUTH_CUSTOMER, JSON.stringify(res.data.user_info));
                dispatch(setAuthFlag(true))
                showToast(res.data.success,'success');    
                formik.resetForm();
                dispatch(getCartItems());
                dispatch(dialogToggle());
            }    
            else
            showToast(res.data.error,'error');        
        }
        }catch (error) {
            
        }
    }
    const {        
        email_id='',
        password='',
    } = values;
    return (
        <>
            <p><b>Sign In</b></p>
            <form onSubmit={handleSubmit}>
            <Box>
                <TextField
                    fullWidth
                    margin="normal"
                    error={errors.email_id}
                    size="small"
                    id="email_id"
                    name="email_id"
                    label="Email Address"
                    value={email_id}
                    onChange={handleChange}
                    helperText={errors.email_id || "We'll never share your email with anyone else."}
                />
                <TextField
                    fullWidth
                    margin="normal"
                    error={errors.password}
                    size="small"
                    type="password"
                    id="password"
                    name="password"
                    label="Password"
                    value={password}
                    onChange={handleChange}
                    helperText={errors.password}
                />                            
                <Button
                type="submit"
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                className="common-app-bg"
                size="small"
                >
                Sign In
                </Button>   
                <p>No Account yet? Please <Button variant="text" size="small" onClick={handleAuthTypeToggle}>Sign Up</Button></p>          
            </Box>
            </form>
            </>
    )
}
