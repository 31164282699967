import React from 'react'
import "./StockUpdate.scss";
import {  FormGroup,FormControlLabel,Switch } from '@mui/material'
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CustomEditButton from './../CustomEditButton';
import CustomDeleteButton from './../CustomDeleteButton';
export default function StockUpdate() {
    return (
        <div className="stock">
            <section className="pdc-info">
                <label><b>4” Lakshmi</b></label>
                <div className="cat_sts between-center-flex">
                    <label>Category : <b>LC</b></label>
                    <div className="between-center-flex">
                        <small className="status">Available</small>
                        <CheckCircleIcon/>
                    </div>
                </div>
            </section>
            <section className="update-sec">
            <FormGroup>
                <FormControlLabel control={<Switch size="small" />} label="Out Of Stock?" />
                <FormControlLabel control={<Switch size="small" />} label="New Arrival?" />
                <FormControlLabel control={<Switch size="small" />} label="On Demand?" />
            </FormGroup>
            </section>
            <section className="actions">
                <CustomEditButton />
                <CustomDeleteButton />                
            </section>            
        </div>
    )
}
