import React, { Fragment } from 'react'
import { Accordion, AccordionDetails, AccordionSummary, Button, Typography,Grid } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { styled } from '@mui/material/styles';
import { DataGrid } from '@mui/x-data-grid';
import OrderSummaryItems from './OrderSummaryItems';
import { AddressFormat, DateTimeFormat } from '../../helpers/functions/CustomFunctions';
import { useState } from 'react';
import axios from 'axios';
import { SHOW_ORDER_BREAKDOWN } from '../../helpers/constants/ApiPaths';
import OrdersList from './mob_view/OrdersList';
import DownloadIcon from '@mui/icons-material/Download';
import "./OrdersReceivedList.scss";
import { updateOrder, updatePayment } from '../../redux/actions';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
const columns = [
  { field: 'product_name', headerName: 'Item', width: 500 },
  { field: 'price', headerName: 'Rate', width: 160,
    renderCell:(params) => <div>&#8377;{params.row.price}</div> 
  },
  {
    field: 'quantity',
    headerName: 'Quantity',
    width: 160,
  },
  {
    field: 'total',
    headerName: 'Sub Total',
    sortable: false,
    width: 160,
    renderCell:(params) => <div>&#8377;{params.row.total}</div>     
  },
];

const CustomTable = styled('table')(({ theme }) => ({
    width: "100%",  
  }));
  const CustomButton = styled(Button)(({ theme }) => ({
    fontSize: '11px',
    textTransform: 'initial',
}));

  
export default function OrdersReceivedList({record = {}, statusType = ''}) {
  const {
    order_details = [],
    email = '',
    address = '',
    created_at = '',
    invoice_pdf='',
    name = '',
    order_id = '',
    // order_payment_method = '',
    order_status = '',
    payment_status = '',
    phone_number = '',
    // total_amount = '',
    // total_items = '',
    user_id = '',
      
  } = record;
  const dispatch = useDispatch();
  const isFromOrderPotal = useLocation().pathname.includes('orderportal');
  const [expanded, setExpanded] = useState(false);
  const [loading,setLoading] = useState(false);
  const [orderItems,setOrderItems] = useState(false);

  const showOrderBreakdown = async () => {
    setLoading(true);
    await axios({
        url : SHOW_ORDER_BREAKDOWN,
        method:'GET',
        params: {
            orderId:order_id,
        }
    }).then(response => {
        const {success,data} = response.data;
        if(success){
            
        }
    }).catch(error => {
        if(error.response){

        }
    }).finally(() => {
      setLoading(false)
    })
    
  }

  const handleChange = (panel) => (event, isExpanded) => {
    // if(isExpanded){
    //   showOrderBreakdown(panel)
    // }
    setExpanded(isExpanded ? panel : false);
  };
  const handleUpdate = (event) => {
    dispatch(updatePayment(order_id,user_id,statusType));
    event.stopPropagation();
  }
  const handleOrderStatus = (event,status) => {
    dispatch(updateOrder(order_id,user_id,status,isFromOrderPotal))
    event.stopPropagation();
  }

  const getPaymentStatus = () => {
    switch (payment_status) {
      // case "Received":         
      // case "Cancelled":
      // return (<Typography variant="subtitle2" gutterBottom component="div">Not Made	</Typography>)  
      case "pending":
        if(isFromOrderPotal)
        return (<CustomButton variant="contained" size="small" onClick={handleUpdate}>Update Payment</CustomButton>)   
        else
        return (<Typography variant="subtitle2" gutterBottom component="div">Not Made	</Typography>)
      // case "Dispatch Pending":
      //   return (<Typography variant="subtitle2" gutterBottom component="div">Yes</Typography>)
      case "completed":        
        return(<Typography variant="subtitle2" gutterBottom component="div">Received</Typography>)
      default:
        return(<p></p>)
    }
  }
  const ordersList = Array.isArray(order_details[0]) ? order_details[0] : [order_details[0]];

  return (
    <Fragment>
        <div className="orders-mob">
            <OrdersList record={record} handleOrderStatus={handleOrderStatus} handleUpdate={handleUpdate} isFromOrderPotal={isFromOrderPotal}/>
        </div>
        <div className="orders-exc-mob">
          <Accordion expanded={expanded === order_id} onChange={handleChange(order_id)} >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
              className="list-bg"
            >
              <Grid container rowSpacing={1} spacing={4}>
                <Grid item>
                    <Typography variant="caption" display="block" gutterBottom>Date : <b>{DateTimeFormat(created_at,'YYYY-MM-DD')}</b></Typography>
                    <Typography variant="caption" display="block" gutterBottom>Order ID #: <b>{order_id}</b>	</Typography>
                    <div className="cus-info">
                      <label><b>{name}</b></label>
                      <address>
                      <Typography variant="body2" gutterBottom component="div">{email}	</Typography>
                      <Typography variant="body2" gutterBottom component="div">{phone_number}</Typography>
                      <Typography variant="body2" gutterBottom component="div">{AddressFormat(address)}</Typography>
                    </address>
                    </div>
                </Grid> 
                <Grid item>
                    <Typography variant="caption" display="block" gutterBottom>Payment Status</Typography>
                      {order_status === "cancel" ?
                        <Typography variant="subtitle2" gutterBottom component="div">Not Made	</Typography>  
                      :
                        getPaymentStatus()
                      }
                    </Grid> 
                <Grid item>
               
                    <Typography variant="caption" display="block" gutterBottom>Dispatch Status</Typography>
                    {["booked","cancel"].includes(order_status)
                      ?
                      <Typography variant="subtitle2" gutterBottom component="div">Yet to be	</Typography>
                      : (order_status === 'completed')
                        ?
                        <Typography variant="subtitle2" gutterBottom component="div">Delivered	</Typography>
                        :
                         (isFromOrderPotal) ?
                          <CustomButton variant="contained" size="small" onClick={(event) =>handleOrderStatus(event,'completed')}>Update Dispatch</CustomButton>
                          :
                          <Typography variant="subtitle2" gutterBottom component="div">Yet to be	</Typography>
                    }
                  </Grid> 
                <Grid item>
                    {(order_status === "cancel") && 
                      <>
                      <Typography variant="caption" display="block" gutterBottom>Order Status</Typography>
                      <Typography variant="subtitle2" gutterBottom component="div">Cancelled</Typography>
                      </>
                    }      
                </Grid>  
                {(order_status === 'booked') &&
                  <Grid item alignSelf="center">
                    <Button variant="outlined" color="error" onClick={(event) =>handleOrderStatus(event,'cancel')}>Cancel Order</Button>
                  </Grid>
                } 
                {invoice_pdf && 
                  <Grid item alignSelf="center">
                    <Button href={invoice_pdf} target='_blank' download variant="outlined" color="primary" startIcon={<DownloadIcon />}>Invoice</Button>
                  </Grid>
                }
              </Grid>  
            </AccordionSummary>
            <AccordionDetails>
                {/* Count  */}
                <OrderSummaryItems data={record}/>
                {/* Count  */}
              <Typography className='center-text mt-1'>
              <b><u>Order List</u></b>
              </Typography>
              <div style={{ height: 400, width: '100%' }}>
              <DataGrid
                    rows={ordersList}
                    columns={columns}
                    pageSize={10}
                    rowsPerPageOptions={[10]}  
                    getRowId={(row)=>row.product_id}
                />
                </div>
            </AccordionDetails>
          </Accordion>
        </div>
    </Fragment>
  )
}
