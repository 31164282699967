import { createSlice } from '@reduxjs/toolkit';

export const AuthCustomerSlice = createSlice({ 
    name:"authCustomer",
    initialState: {
        flag:false
    },
    reducers: {
        setAuthFlag: (state,action) => { 
            const { payload } = action;
            state.flag = payload;
        }
    } 
});

export const {setAuthFlag  } = AuthCustomerSlice.actions;
export default AuthCustomerSlice.reducer; 