import React from 'react'
import "./OrdersList.scss";
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import {  Button,IconButton } from '@mui/material'
import { styled } from '@mui/material/styles';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { AddressFormat, DateTimeFormat } from '../../../helpers/functions/CustomFunctions';
const CustomButton = styled(Button)(({ theme }) => ({
    fontSize: '11px',
    textTransform: 'initial',
}));

export default function OrdersList({record, handleOrderStatus,handleUpdate,isFromOrderPotal}) {
    const {
        order_details = [],
        email = '',
        address = '',
        created_at = '',
        invoice_pdf='',
        name = '',
        order_id = '',
        order_status = '',
        payment_status = '',
        phone_number = '',
        user_id = '',          
      } = record;
    const getOrderStatus = (status) => {
        switch (status) {
            case 'completed':
                return(<div className="between-center-flex">
                    <small className="status">Delivered</small><CheckCircleIcon/>
                </div>)
            case 'cancel':
                return(<div className="between-center-flex">
                    <small className="status">Cancelled</small>
                </div>)        
            default:
                break;
        }
    }
    return (
        <div>
            <section className="order-info">
                <h6>Order#: <b>{order_id}</b></h6>
                <div className="date_sts between-center-flex">
                    <small>{DateTimeFormat(created_at,'YYYY-MM-DD')}</small>
                    {getOrderStatus(order_status)}                        
                </div>
            </section>
            <section className="customer-info">
                <h5>{name}</h5>
                <p>{email}</p>
                <address>
                {AddressFormat(address)}
                </address>
            </section>
            <section className="actions">
                {(payment_status === 'pending') &&
                    (isFromOrderPotal ?
                    <CustomButton variant="contained" size="small" onClick={handleUpdate}>Update Payment</CustomButton>
                    :
                    <small className="status"><b>Not Paid</b></small>)
                }
                {(order_status === 'dispatch_pending') &&
                    (isFromOrderPotal ?
                    <CustomButton variant="contained" size="small" onClick={(event) =>handleOrderStatus(event,'completed')}>Update Dispatch</CustomButton>
                    :
                    <small className="status"><b>Not Dispatched</b></small>)
                }
                {invoice_pdf && <IconButton aria-label="delete" color="info" href={invoice_pdf} download target='_blank'>  <FileDownloadOutlinedIcon /></IconButton> }
                {(order_status === 'booked') &&
                <Button variant="outlined" color="error" onClick={(event) =>handleOrderStatus(event,'cancel')}>Cancel Order</Button>
                }
            </section>            
        </div>
    )
}
