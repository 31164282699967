import React, { useEffect, useState } from 'react'
import HowToOrder from './HowToOrder'
import Grid from '@mui/material/Grid';
import ProductCard from '../common/ProductCard';
import Alert from '@mui/material/Alert';
import { styled } from '@mui/material/styles';
import { GET_COMBO } from '../../helpers/constants/ApiPaths';
import AxiIns from '../../helpers/API';

const CustomAlert = styled(Alert)(({ theme }) => ({
    textAlign:'center !important',
    display:'block !important',
    marginTop:'1rem',
    fontWeight:'bold',
    fontSize:'18px',
    padding:'10px',
    border: '1px solid #ccced0',
    background:'none'
}));

export default function Combos() {
  const [list,setList] = useState([]);   
    
  const getProducts = async() => {     
    try{
        const payload = {
            method: 'POST',
            url: GET_COMBO              
        };
        const res = await AxiIns(payload);
        if(res.status === 200){
            if(res.data.status === 'success') 
            setList(res.data.data) 
        }
    }catch(error){

    }
    finally{
        //setLoading(false)
    }      
  }
  const handleSuccessCallback = () => {
    getProducts();
  }
  useEffect(() => {
    getProducts();
  },[])

  return (
    <div className='dynamic-content'>        
        <HowToOrder/>      
        <div>
        <CustomAlert icon={false} severity="info" >COMBOS</CustomAlert>
        <Grid 
          container 
          // spacing={{ xs: 2, md: 3 }} 
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
          > 
            {list.map((ele,index) =>
              <Grid item xs={12} sm={6} md={4} lg={3} xl={2} key={`pdc_${index}`}>
                <ProductCard detail={ele} key={`pdc_${index}`} successCallback={handleSuccessCallback}/>
                </Grid>
            )}
            </Grid>
        </div>      
    </div>
  )
}
