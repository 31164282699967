import moment from "moment";
import { AUTH_CUSTOMER, AUTH_TOKEN, AUTH_USER } from "../constants/LocalStorage";
import { toast } from 'react-toastify';

// #rbp Custom Alert Message
export const CustomAlertMessage = (message, type, enqueueSnackbar) => {
    enqueueSnackbar(message, {
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'center',
      },
      variant: type,
      autoHideDuration:2000
    });
  };

export function DateTimeFormat(date, format) {
  if (moment(date).isValid()) {
    return moment(date).format(format);
  } else {
    return '';
  }
}  

export function AddressFormat(address = '',city = '',state = '') {
  let formattedAddress = address;
  if(city)
  formattedAddress = `${formattedAddress}, ${city}`
  if(state)
  formattedAddress = `${formattedAddress}, ${state}`
  return formattedAddress;
}

export const authUserEmail = () => {
  const {email = ''} = JSON.parse(localStorage.getItem(AUTH_USER));
  return email;
}
export const authUserToken = () => {
  return localStorage.getItem(AUTH_TOKEN);
}

export const isMobileScreen = () => {
  return window.innerWidth < 768 ? true : false;
};

export const isTabScreen = () => {
  return (window.innerWidth > 375 && window.innerWidth <= 768) ? true : false;
}

export const showToast = (message,type) => {
    const toastConfig = {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    }
    switch (type) {
      case 'success':
        toast.success(message,toastConfig);
        break;
      case 'info':
        toast.info(message,toastConfig);
        break;
      case 'warning':
        toast.warn(message,toastConfig);
        break;
      case 'error':
        toast.error(message,toastConfig);
        break;
      default:
        toast(message,toastConfig);
        break;
    }
}


//Customer
export const authCustomer = () => {
  return JSON.parse(localStorage.getItem(AUTH_CUSTOMER));
}

//Common
export const scrollToTop = () => {
  window.scrollTo({
    top: 0, 
    behavior: 'smooth'      
  });
}