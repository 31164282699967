export const CUSTOMER_INITIAL = '/'
export const CUSTOMER_SHOP = CUSTOMER_INITIAL + 'shop';
export const CUSTOMER_NEW_ARRIVALS = CUSTOMER_INITIAL + 'newArrivals';
export const CUSTOMER_HOT_SELLING = CUSTOMER_INITIAL + 'hotSelling';
export const CUSTOMER_COMBOS = CUSTOMER_INITIAL + 'combos';
export const CUSTOMER_PAYMENT = CUSTOMER_INITIAL + 'payment';
export const CUSTOMER_SHIPPING_POLICY = CUSTOMER_INITIAL + 'shippingPolicy';
export const CUSTOMER_CART = CUSTOMER_INITIAL + 'cart';
export const CUSTOMER_TESTIMONIALS = CUSTOMER_INITIAL + 'testimonials';
export const CUSTOMER_ACCOUNT = CUSTOMER_INITIAL + 'myAccount';

//Order Portal Paths
export const ORDER_PORTAL_INITIAL = '/orderportal'
export const ORDER_PORTAL_SIGNIN = ORDER_PORTAL_INITIAL + '/signin'
export const ORDER_PORTAL_DASHBOARD = ORDER_PORTAL_INITIAL + '/dashboard'
export const ORDERS_RECEIVED = ORDER_PORTAL_INITIAL + '/orders-received'
export const PAYMENT_PENDING = ORDER_PORTAL_INITIAL + '/payment-pending'
export const DISPATCH_PENDING = ORDER_PORTAL_INITIAL + '/dispatch-pending'
export const COMPLETED_ORDERS = ORDER_PORTAL_INITIAL + '/completed-orders'
export const CANCELLED_ORDERS = ORDER_PORTAL_INITIAL + '/cancelled-orders'
export const ADD_PRODUCT = ORDER_PORTAL_INITIAL + '/add-product'
export const UPDATE_PRODUCT = ORDER_PORTAL_INITIAL + '/update-product'