import { createSlice } from '@reduxjs/toolkit';

export const ProductsReducer = createSlice({ 
    name:"productsList",
    initialState: {
        loading : false,
        items : []
    },
    reducers: {
        setProducts: (state,action) => { 
            const { payload } = action;
            state.items = payload;
        }
    } 
});

export const {setProducts  } = ProductsReducer.actions;
export default ProductsReducer.reducer; 