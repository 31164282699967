import React from 'react'
import HowToOrder from './HowToOrder'
import CategoryWiseProductsTab from './CategoryWiseProductsTab';

export default function Shop() {
  return (
    <div className='dynamic-content'>        
        <HowToOrder/>      
        <CategoryWiseProductsTab />      
    </div>
  )
}
