import React, { useState } from 'react'

import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';

import TextField from '@mui/material/TextField';
import { authCustomer, showToast } from '../../helpers/functions/CustomFunctions';
import { useDispatch } from 'react-redux';
import { dialogToggle } from '../../redux/actions/reducers/AuthenticationDialogReducer';
import { addItemToCart } from '../../redux/actions';
const dummyImg = `${process.env.PUBLIC_URL}/images/products/png/pdc-1.png`;

export default function ProductCard({detail = {},successCallback}) {
    const [purQuantity,setQuantity] = useState('');
    const dispatch = useDispatch();

    const {
        brand_name= '',
        // category_name= '',
        // display_type= '',
        id= '',
        product_description= '',
        product_discount= '',
        product_image= '',
        product_link = '',
        product_mrp='',
        product_name='',
        // status='',
        stock_quantity='',
    } = detail;
    
    const handleAddCart = () => {
        const auth = authCustomer();
        if(!auth){
            showToast('Please sign in and then add to cart!', 'warning');
            dispatch(dialogToggle());
        }else{
            if(!purQuantity){
                showToast('Please enter a valid quantity!', 'warning');
            }else{                
                const formData = {
                    product_id:id,
                    quantity:purQuantity
                }
                dispatch(addItemToCart(formData));  
                setQuantity('');    
                successCallback()          
            }
        }
    }
    const handleChange = (e) => {
        const {value} = e.target;
        setQuantity(value);
    }
    const inputProps = {
        step: 1,
        min:1,
        style:{
            height:'17px', 
            width:'80px'
        }
    };      
    const hasStock = (stock_quantity && parseInt(stock_quantity) > 0);
    return (    
        <div className={`product-container`}>
            <div className={"product-card"}>
                <div className={"product-card-head"}>
                    <span className="brand-badge">{brand_name}</span>
                    <img src={product_image} alt="pdc" className="product-img hover-img" />                   
                </div>
                <div className={"product-card-body"}>
                    <div className="product-desc">
                        <span className="product-title between-space">
                            <b>{product_name}</b>                            
                        </span>
                        <span className="product-caption">{product_description}</span>                       
                    </div>
                    <div className="product-properties">
                        <span className="product-size">
                            <h4><del>&#x20b9;{product_mrp}</del> from <span className="real-price">&#x20b9;{product_discount}</span></h4>                          
                        </span>
                        {hasStock &&
                            <div className='around-space available-height center-item quantity-sec'>
                                <div><label>Quantity</label></div>
                                <div>
                                <TextField name="purQuantity" value={purQuantity} onChange={handleChange} id="outlined-basic" variant="outlined" type={'number'} size="small" inputProps={inputProps}/>                            
                                </div>
                            </div>
                        }
                        <div className="product-color">                            
                            {hasStock ?
                                <div className="addtocart" onClick={handleAddCart}>
                                    <div className="pretext">
                                        <div className='between-space'>
                                            <p>Add To Cart</p>
                                            <ShoppingCartIcon />
                                        </div>
                                    </div>                 
                                </div>                       
                            : <div className="badge">Out Of Stock</div>
                            }

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
