import React from 'react'
import { experimentalStyled as styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import { useNavigate } from 'react-router-dom';
import { CUSTOMER_SHOP } from '../../helpers/constants/RoutePaths';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
    // border: "2px solid #035050",
    // borderRadius: '50%',
    textAlign: '-webkit-center',
    //width: '220px',
    height: '220px',
    margin: '5px',
    // borderStyle:'dashed'
  }));

export default function CategoryCard({title,image}) {
  const navigate = useNavigate();
  const goToCategory = () => {
    navigate(`${CUSTOMER_SHOP}/${title}`)
  }

  return (
    <Item className='cat-card shadow-effect' onClick={goToCategory}>
        <div>
            <img src={image} alt="category" width={120}/>
        </div>
        {title && 
            <h4><b>{title}</b></h4>
        }
    </Item>
  )
}
