import { createSlice } from '@reduxjs/toolkit';
import { SHOW_ALL_ORDERS_INITIALS } from './../store/InitialStore';

export const OrdersReducer = createSlice({ 
    name:"orders",
    initialState: {
        list: [],
        loading: false
    },
    reducers: {
        setOrdersList: (state,action) => { 
            const { payload } = action;
            state.list = payload;
        }
    } 
});

export const {setOrdersList  } = OrdersReducer.actions;
export default OrdersReducer.reducer; 