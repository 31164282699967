import React from 'react'
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import TextField from '@mui/material/TextField';
import Avatar from '@mui/material/Avatar';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Grid from '@mui/material/Grid';
import { useState } from 'react';
import SignUp from './SignUp';
import SignIn from './SignIn';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
      padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
      padding: theme.spacing(1),
    },
    '& .MuiDialog-paper': {
      background: 'rgb(255 255 255 / 69%)',
      backdropFilter: 'blur(12px)',
      borderRadius: '22px',
      // top:0,
      // right:0,
      // position:'absolute',
    },
    '& .MuiBackdrop-root': {
      background:'none'
    }
  }));
  
  const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;
  
    return (
      <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
        {/* {children} */}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => 'grey',
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
    );
  };

export default function AuthenticationModal({handleDialogFlag,dialogFlag}) {

  const [authType,setAuthTypeToggle] = useState(true);
  
  const handleSubmit = (event) => {
    event.preventDefault();   
  };

  const handleAuthTypeToggle = () => {
    setAuthTypeToggle(!authType);
  }
  

  return (
    <BootstrapDialog
        onClose={handleDialogFlag}
        aria-labelledby="customized-dialog-title"
        open={dialogFlag}
        maxWidth="xs" 
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleDialogFlag}>
        </BootstrapDialogTitle>
        <DialogContent >
          <Grid container spacing={2}>
            <Grid item xs={12}>            
              <div>   
                <center>
                  {authType ?
                    <SignIn handleAuthTypeToggle={handleAuthTypeToggle} />
                  :
                    <SignUp handleAuthTypeToggle={handleAuthTypeToggle} />
                  }
                </center>
              </div>
            </Grid>  
          </Grid>
        </DialogContent>        
      </BootstrapDialog>
  )
}
