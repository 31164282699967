import { createSlice } from '@reduxjs/toolkit';

export const AuthDialogSlice = createSlice({ 
    name:"authDialog",
    initialState: {
        flag:false
    },
    reducers: {
        dialogToggle: (state,action) => { 
            // const { payload } = action;
            state.flag = !state.flag;
        }
    } 
});

export const {dialogToggle  } = AuthDialogSlice.actions;
export default AuthDialogSlice.reducer; 