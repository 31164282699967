const BASE_URL = 'http://crackersmart-env.eba-wihnpa2w.ap-south-1.elasticbeanstalk.com/api/';
export const LOGIN_URL= BASE_URL + 'login';
export const SHOW_ALL_ORDERS = BASE_URL + 'showallorders';
export const SHOW_ORDER_BREAKDOWN = BASE_URL + 'showorderbreakdown';

//Admin
export const ADMIN_ORDERS_LIST="order_list"
export const ADMIN_UPDATE_PAYMENT = "admin_update_payment"
export const ADMIN_UPDATE_ORDER = "admin_update_order_status"
export const ADMIN_PRODUCTS_LIST = "product_list"
export const ADMIN_PRODUCT_STATUS_UPDATE = "update_product_status"
export const ADMIN_PRODUCT_DELETE = "product_delete"
export const ADMIN_PRODUCT_ADD = "add_product"
export const ADMIN_PRODUCT_UPDATE = "update_product"
export const ADMIN_PRODUCT_VIEW = "view_product"

//Customer
export const SIGNUP_API = 'signup'
export const SIGNIN_API = 'login'
export const CART_ITEMS_API = 'view_cart'
export const ADD_CART = 'add_cart'
export const REMOVE_CART = 'remove_cart'
export const ORDER_SUBMIT = 'order_submit';
export const GET_COMBO = 'get_combo';
export const PRODUCT_FILTER = 'product_filter'
export const CATEGORY_FILTER = 'category_filter'
export const MY_ORDERS = 'user_order_list'
