import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Alert from '@mui/material/Alert';
import { MINIMUM_ORDER_VALUE, PACKAGING_CHARGE_PERCENTAGE } from '../../helpers/constants/AppValues';
import {  Grid, } from '@mui/material'
import TextField from '@mui/material/TextField';
import LoadingButton from '@mui/lab/LoadingButton';
import CartProducts from './CartProducts';
import AlertTitle from '@mui/material/AlertTitle';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { CUSTOMER_ACCOUNT, CUSTOMER_SHOP } from '../../helpers/constants/RoutePaths';
import {Link, useNavigate} from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import { ORDER_SUBMIT } from '../../helpers/constants/ApiPaths';
import AxiIns from '../../helpers/API';
import { authCustomer, showToast } from '../../helpers/functions/CustomFunctions';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { getCartItems } from '../../redux/actions';
const CustomButton= styled(Button)(({ theme }) => ({
    marginTop:'1rem'  , 
    textTransform: 'none',
}));
const initialValues = {        
    name: "",
    email:"",
    phone_number: "",
    address:"",
    pincode:''
};
const validationSchema = Yup.object({
    name : Yup.string().required('Name is required'),
    email: Yup.string().required('Email address is required'),
    phone_number: Yup.string().required('Phone number is required'),
    address: Yup.string().required('Address is required'),
    pincode: Yup.string().required('Pin code is required'),
});
export default function ShoppingCart() {
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const {items = []} = useSelector((state) => state.cartItems);
    const [amountSplitUp,setAmountSplitUp] = useState({
        totalCart : 0,
        totalPackage: 0,
        totalOrder: 0
    });
    const saveOrder = async(values) => {     
        const {id} = authCustomer();       
        const payload = {
            method: 'POST',
            url: ORDER_SUBMIT,
            params: {
                ...values,
                user_id: id,
                payment_status: "pending",
                order_payment_method: "Online",
                total_amount: amountSplitUp.totalOrder,
                package_charges: amountSplitUp.totalPackage,
            }
        };
        try {      
        const res = await AxiIns(payload);
        if(res.status === 200){
            if(res.data.status === 'success'){
                showToast(res.data.msg,'success');  
                dispatch(getCartItems());  
                navigate(CUSTOMER_ACCOUNT)    
            }
            else
            showToast(res.data.error,'error');        
        }
        } catch (error) {
        
        } 
    }
    
    const calculateSplit = () => {
        const cartTotalPrice = items.reduce((totalAmount,ele) => totalAmount = totalAmount + parseFloat(ele.product_bill),0)
        const packagePrice = parseFloat((PACKAGING_CHARGE_PERCENTAGE / 100) * parseFloat(cartTotalPrice)).toFixed(2);
        setAmountSplitUp({
          totalCart : parseFloat(cartTotalPrice).toFixed(2),
          totalPackage: packagePrice,
          totalOrder: (parseFloat(cartTotalPrice) + parseFloat(packagePrice)).toFixed(2)
        })
    } 
    useEffect(() => {
        calculateSplit();
    },[items]);

    const {handleSubmit,values,handleChange,errors} = useFormik({
        initialValues,
        validationSchema,
        onSubmit: values => {
            saveOrder(values);
        },
    });

    const {
        totalCart = 0,
        totalPackage= 0,
        totalOrder= 0
    } = amountSplitUp;
    const {
        name='',
        address='',    
        email='',
        phone_number='',
        pincode=''
    } = values;
  return (
    <div className='dynamic-content'>  
         <Container maxWidth="lg">
            <Box sx={{ bgcolor: '#fff', padding: '5rem 1rem 1rem 1rem' }}>
                <center><h4><b>SHOPPING CART</b></h4></center>  
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                    <CartProducts/>
                    </Grid>
                </Grid>
                <div>
                <center>
                    <Link to={CUSTOMER_SHOP} className="no-text-decoration">
                    <CustomButton variant="outlined" endIcon={<ArrowForwardIcon />}>
                    Continue Shopping
                    </CustomButton>
                    </Link>
                </center>
                </div>
                <Grid container spacing={5}
                direction="row"
                justifyContent="space-evenly"
                alignItems="flex-start"
                sx={{marginTop:'2rem'}}>
                    <Grid item xs={12} sm={6} md={8} lg={8}>
                        <div>
                        <form onSubmit={handleSubmit}>
                        <Grid container spacing={3}>   
                            <Grid item xs={12}>    
                                <TextField
                                    fullWidth
                                    error={errors.name}
                                    size="small"
                                    id="name"
                                    name="name"
                                    label="Name"
                                    value={name}
                                    onChange={handleChange}
                                    helperText={errors.name}
                                />  
                            </Grid>
                            <Grid item xs={12}>  
                                <TextField
                                    fullWidth
                                    error={errors.email}
                                    size="small"
                                    id="email"
                                    name="email"
                                    label="Email Address"
                                    value={email}
                                    onChange={handleChange}
                                    helperText={errors.email}
                                /> 
                            </Grid>
                            <Grid item xs={12}>        
                                <TextField
                                    fullWidth
                                    error={errors.phone_number}
                                    size="small"
                                    id="phone_number"
                                    name="phone_number"
                                    label="Phone Number"
                                    value={phone_number}
                                    onChange={handleChange}
                                    helperText={errors.phone_number}
                                />
                            </Grid>
                            <Grid item xs={12}>        
                                <TextField
                                    fullWidth
                                    error={errors.address}
                                    size="small"
                                    id="address"
                                    name="address"
                                    label="Address"
                                    value={address}
                                    onChange={handleChange}
                                    multiline
                                    rows={3}
                                    helperText={errors.address}
                                />
                            </Grid>
                            <Grid item xs={12}>        
                                <TextField
                                    fullWidth
                                    error={errors.pincode}
                                    size="small"
                                    id="pincode"
                                    name="pincode"
                                    label="Pin Code"
                                    value={pincode}
                                    onChange={handleChange}
                                    helperText={errors.pincode}
                                />
                            </Grid>
                            <Grid item xs={12}> 
                            <LoadingButton          
                                type="submit"
                                fullWidth
                                variant="contained"
                                className="common-app-bg"
                                disabled={!items.length}
                            >
                                Checkout
                            </LoadingButton>                
                            </Grid>
                        </Grid>
                        </form>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={4}>
                        <Alert severity="info">
                            <AlertTitle>Minimum Order Value</AlertTitle>
                            <strong>Rs.{MINIMUM_ORDER_VALUE}/-</strong>
                        </Alert>
                        <div className='fee-splitup'>
                           <table>
                            <tbody>
                                <tr><td><h5>Total Price</h5></td><td><h3>&#8377;{totalCart}</h3></td></tr>
                                <tr><td><h5>Packaing Charge {PACKAGING_CHARGE_PERCENTAGE}%</h5></td><td><h3>&#8377;{totalPackage}</h3></td></tr>
                                <tr><td><h5>Order Value</h5></td><td><h3>&#8377;{totalOrder}</h3></td></tr>
                            </tbody>
                           </table>
                        </div>
                        
                    </Grid>
                </Grid>
            </Box>
        </Container>   
    </div>
  )
}
