import React, { useEffect, useState } from 'react'
import Slider from 'react-slick'
import ProductCard from '../../common/ProductCard';
import SampleNextArrow from '../../common/SampleNextArrow';
import SamplePrevArrow from '../../common/SamplePrevArrow';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Button from '@mui/material/Button';
import {Paper} from '@mui/material';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import AxiIns from '../../../helpers/API';
import { PRODUCT_FILTER } from '../../../helpers/constants/ApiPaths';

var settings = {  
  className: "center",
      // centerMode: true,
      infinite: false,
      centerPadding: "10px",
      slidesToShow: 4,
      slidesToScroll: 4,
      initialSlide: 4,
      speed: 500,
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />,  
      responsive: [      
        {
          breakpoint: 1076,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            initialSlide: 3 
          }
        },
        {
          breakpoint: 829,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2
          }
        },
        {
          breakpoint: 571,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
  };

const CustomCardHeader = styled(CardHeader)(({ theme }) => ({
  borderBottom: "1px solid #dee2e3",
  backgroundColor:'#e31e24',
  color: '#fff'
}));

  

export default function BannerProducts({title,cardClass,headClass,vClass,path,type}) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [loading,setLoading] = useState(true);
    const [list,setList] = useState([]);
   
    const handleView = () => {
      navigate(path);
    }
    const getBannerProducts = async(type) => {     
      try{
          const payload = {
              method: 'POST',
              url: PRODUCT_FILTER,
              params:{
                  type
              }
          };
          const res = await AxiIns(payload);
          if(res.status === 200){
              if(res.data.status === 'success') 
              setList(res.data.data) 
          }
      }catch(error){

      }
      finally{
          //setLoading(false)
      }      
    }
    const handleSuccessCallback = () => {
      getBannerProducts(type);
    }
    useEffect(() => {
      getBannerProducts(type);
    },[])

  return (
    <Box>
      <Paper elevation={2}>
        <Card >
          <CustomCardHeader     
            action={
              <Button variant="outlined" startIcon={<VisibilityIcon />} size="small" className="view-all" onClick={handleView}>
              View All
            </Button>
            }
            title={title}
            className="banner-card-header"
          />
          <CardContent>

            <div className="slider-block">
                <Slider {...settings}>
                  {list.map((rec,ind) => 
                    <div key={`pdc_${type}_${ind}`}>
                      <ProductCard detail={rec} key={`pdc_${type}_${ind}`} successCallback={handleSuccessCallback}/>
                    </div>                     
                  )}
                </Slider>
              </div>
          </CardContent>     
        </Card>
      </Paper>
    </Box>    
  )
}
