import React from 'react'
import { experimentalStyled as styled } from '@mui/material/styles';

const Item = styled('div')(({ theme }) => ({
    padding: theme.spacing(2),
    textAlign: 'center',
  }));

export default function BrandCard({image}) {
  return (
    <Item>
        <img className='circle-image hover-img' src={`${process.env.PUBLIC_URL}/images/brands/${image}.png`} alt="Brand" width={90}/>
    </Item>
  )
}
