import React from 'react'
import CommonProductForm from './common/CommonProductForm';

const initialValues = {
  brand_name: "",
  category_name: "",
  product_name: "",
  product_link: "",
  product_mrp: "",
  product_discount: "",
  stock_quantity: "",
  image: '',
  product_description: "",
  product_image : ''
}

export default function AddProduct() { 

  const pdcProps = {
    initialValues,
    isAddForm:true,
    successCallback : () => {}
  };
  return (
    <CommonProductForm {...pdcProps}/>
  )
}
