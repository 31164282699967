import React from 'react'
import Slider from "react-slick";
import SampleNextArrow from '../common/SampleNextArrow';
import SamplePrevArrow from '../common/SamplePrevArrow';
import Rating from '@mui/material/Rating';
import { REVIEWS } from '../../helpers/constants/AppValues';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import {  Grid, } from '@mui/material'

var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
    autoplay: false,
    pauseOnHover: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />
};

export default function Testimonials({from}) {
  return (
    <>
    {(from === 'home') ?
    <div className="slider-block">
        <Slider {...settings}>
            {REVIEWS.map(({name,review,msg},index) => 
                <div className='slide-record testimoni-card' key={`rev_${index}`}>
                    <div className="center-text">               
                        {name && <p><b>{name}</b></p>}
                        {review && <Rating name="read-only" value={review} readOnly />}
                        {msg && <p>{msg}</p> }
                    </div>                    
                </div>                       
            )}
        </Slider>
    </div> 
:
    <div className='dynamic-content'>  
    <Container maxWidth="lg">
        <Box sx={{ bgcolor: '#fff', padding: '5rem 1rem 1rem 1rem' }}>
            <div className="center-text">
            <h4><b>TESTIMONIALS</b></h4>   
            <p className='payment-desc'>Some genuine reviews of our customers from Google review.</p>                    
            </div>
            <Grid container spacing={2}
            direction="row"
            >
                <Grid item xs={12}>
                    {REVIEWS.map(({name,review,msg},index) => 
                        <div key={`rev_${index}`} className="review-card">
                            {name && <p><b>{name}</b></p>}
                            {review && <Rating name="read-only" value={review} readOnly />}
                            {msg && <p>{msg}</p> } 
                        </div>
                    )}
                </Grid>
            </Grid>
        </Box>
    </Container>   
    </div>
}</>
  )
}
