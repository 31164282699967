import { Button, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import CustomDeleteButton from '../common/CustomDeleteButton';
import CustomEditButton from '../common/CustomEditButton';
import StockUpdate from './../common/mob_view/StockUpdate';
import "./UpdateProduct.scss";
import { useDispatch, useSelector } from 'react-redux';
import { deleteProduct, getProducts, updateProductStatus } from '../../redux/actions';
import NoRecordFound from '../common/NoRecordFound';
import EditProductModal from '../common/modals/EditProductModal';
import { ADMIN_PRODUCT_VIEW } from '../../helpers/constants/ApiPaths';
import AxiIns from '../../helpers/API';
const formInitial = {
  brand_name: "",
  category_name: "",
  product_name: "",
  product_link: "",
  product_mrp: "",
  product_discount: "",
  stock_quantity: "",
  image: '',
  product_description: "",
  product_image : ''
}

export default function UpdateProduct() {
  const dispatch = useDispatch();
  const [flag,setFlag] = useState(false);
  const [initialValues,setInitialValues] = useState(formInitial);
  const {loading = false, items = []} = useSelector(state => state.productsList);
  
  useEffect(() => {
    dispatch(getProducts());
  }, []);

  const handleDialogFlag = () => {
    setFlag(prevSt => !prevSt);
  };

  const tableRecord = items.map((row) => {
    const {
      // brand_name = '',
      category_name = '',
      display_type = '',
      id = '',
      out_of_stock_staus = '',
      // product_description = '',
      // product_discount = '',
      // product_image = '',
      // product_link = '',
      // product_mrp = '',
      product_name = '',
      status = 0,
      // stock_quantity = '',
    } = row;
    const handleProductStatus = (status) => {
      const payload = {
        product_id:id,
        status
      }
      dispatch(updateProductStatus(payload));
    }
    const handleDelete = () => {
      dispatch(deleteProduct(id));
    }
    const handleEdit = async() => {
      try {      
        const payload = {
          method : "GET",
          url : ADMIN_PRODUCT_VIEW,
          params : {
            product_id: id
          }
        };
        const res = await AxiIns(payload);
        if(res.status === 200){
            if(res.data.status === 'success'){
              setInitialValues(res.data.data)
            }       
        }
      } catch (error) {
      
      }       
      setFlag(true);
    }
    return(
    <TableRow
        key={`pdc_${id}`}
        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
      >
        <TableCell component="th" scope="row">
        {product_name}
        </TableCell>
        <TableCell >{category_name}	</TableCell>
        <TableCell >{out_of_stock_staus === '0' ? 'Available' : 'Not Available'}</TableCell>
        <TableCell >{out_of_stock_staus === '0' && <Button variant="contained" color="error" size="small" className="table-btn" onClick={() => handleProductStatus('out_of_stock')}>Out Of Stock</Button>}</TableCell>
        <TableCell >{display_type === "new_arrival" ? "Yes" : "No"}</TableCell>
        <TableCell >{display_type !== "new_arrival" && <Button variant="contained" color="success" size="small" className="table-btn" onClick={() => handleProductStatus('new_arrival')}>Make it New?</Button>}</TableCell>
        <TableCell >{display_type === "hot_sell" ? "Yes" : "No"}</TableCell>
        <TableCell >{display_type !== "hot_sell" && <Button variant="contained" color="success" size="small" className="table-btn" onClick={() => handleProductStatus('on_demand')}>In Demand?</Button>}</TableCell>
        <TableCell className="flex-grid">
          <CustomDeleteButton handleClick={handleDelete}/>
          <CustomEditButton handleClick={handleEdit}/>
        </TableCell>            
    </TableRow>
  )})

  return (
    <div>
        <Typography variant="h5" gutterBottom component="div" className='center-text'>Stock Update</Typography>
        <div className="mob-view">
          <StockUpdate />
          <StockUpdate />
        </div> 
        <div className="exc-mob-view">
          <TableContainer component={Paper}>
          {items.length > 0 ?      
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead className='list-bg'>
                <TableRow>
                  <TableCell>Product Name	</TableCell>
                  <TableCell>Category</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Update</TableCell>
                  <TableCell>New Arrival?	</TableCell>
                  <TableCell>Update	</TableCell>
                  <TableCell>on Demand?		</TableCell>
                  <TableCell>Update	</TableCell>
                  <TableCell>Actions	</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {tableRecord}                  
              </TableBody>
            </Table>
            : <NoRecordFound />}
          </TableContainer>
        </div>
        {flag && 
          <EditProductModal  handleDialogFlag={handleDialogFlag} dialogFlag={flag} initialValues={initialValues}/> 
        }
    </div>
  )
}
 