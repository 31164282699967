import * as React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Slider from "react-slick";
import BannerProducts from './banners/BannerProducts';
import SampleNextArrow from '../common/SampleNextArrow';
import SamplePrevArrow from '../common/SamplePrevArrow';
import FeatureCard from '../common/FeatureCard';
import BannerCategories from './banners/BannerCategories';
import BrandCard from '../common/BrandCard';
import { CUSTOMER_HOT_SELLING, CUSTOMER_NEW_ARRIVALS, CUSTOMER_SHOP } from '../../helpers/constants/RoutePaths';
import Testimonials from './Testimonials';



const brands = ['Bluestar','MSP','ARGO','ajantha','Ananda','Arun','RK','Thilagavathi'];


var settings = {
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  fade: true,
  autoplay: false,
  pauseOnHover: true,
  nextArrow: <SampleNextArrow />,
  prevArrow: <SamplePrevArrow />
};

export default function Home() {  

  return (    
      <div>
        <div className="slider-block main-slider-block">
          <Slider {...settings}>
            <div className='slide-record'>
              <img src={`${process.env.PUBLIC_URL}/images/sliders/slider_1.png`} alt="No Img" className="full-width"  />
            </div>
            <div className='slide-record'>
              <img src={`${process.env.PUBLIC_URL}/images/sliders/slider_2.png`} alt="No Img" className="full-width"/>
            </div>
            <div className='slide-record'>
              <img src={`${process.env.PUBLIC_URL}/images/sliders/Banners 04.png`} alt="No Img" className="full-width"/>
            </div>          
          </Slider>
        </div>        
         
        <Container sx={{ py: 8 }} maxWidth="xl">
          <BannerProducts type="new_arrival" title="New Arrivals" cardClass="new-arri-body" headClass="new-arri-head" vClass="new-arri-v" path={CUSTOMER_NEW_ARRIVALS} key='new_arrival'/>
        </Container>

        <Container maxWidth="xl">
          <div>
            <img src="/images/offer_banner.png" alt="Offer Banner" className="full-width"/>
          </div>
        </Container>

        <Container sx={{ py: 6 }} maxWidth="xl">
          <BannerProducts type="hot_sell" title="Hot Selling" cardClass="hot-sell-body" headClass="hot-sell-head" vClass="hot-sell-v" path={CUSTOMER_HOT_SELLING} key="hot_sell"/>
        </Container>

        <Container sx={{ py: 4 }} maxWidth="xl" style={{background: `url('${process.env.PUBLIC_URL}/images/crackersbg.gif')`}}>
          <div className='center-text' id="shop_with_us_head">
          <Typography variant="h3" gutterBottom>Why Shop with Us?</Typography>
          </div>  
          <Grid 
          container 
          spacing={{ xs: 2 }} 
          // columns={{ xs: 12, sm: 12, md: 10, lg: 12}}
          direction="row"
          justifyContent="center"
          alignItems="stretch"
          >
              <Grid item xs={12} sm={4} md={3} lg={2}>
                <FeatureCard logo={"bi bi-star-fill"} title={'QUALITY & SAVING'} desc={'Direct Factory Sale - Right Value - Right Quality'}/>
              </Grid>
              <Grid item xs={12} sm={4} md={3} lg={2}>
                <FeatureCard logo={'bi bi-palette2'} title={'MORE VARITIES'} desc={'Tailor-made combos for all type of needs'}/>
              </Grid>
              <Grid item xs={12} sm={4} md={3} lg={2}>
                <FeatureCard logo={'bi bi-truck'} title={'FAST SHIPPING'} desc={'Goods will reach you in most efficient time'}/>
              </Grid>
              <Grid item xs={12} sm={4} md={3} lg={2}>
                <FeatureCard logo={'bi bi-shield-shaded'} title={'EASY PAYMENT'} desc={'Select * Place order * Pay Online * Whatsapp Details'}/>
              </Grid>
              <Grid item xs={12} sm={4} md={3} lg={2}>
                <FeatureCard logo={'bi bi-question-diamond-fill'} title={'HAVE QUESTIONS'} desc={"24/7 Customer Service - We're here & Happy to help!"}/>
              </Grid>
          </Grid>
        </Container>

        <Container sx={{ py: 6 }} maxWidth="xl">
          <BannerCategories title="Shop By Categories" cardClass="banner-cat-body" headClass="banner-cat-head" vClass="banner-cat-v" path={CUSTOMER_SHOP}/>
        </Container>

        <Container sx={{ py: 3 }} maxWidth="xl">
          <div className='center-text' id="testi-head">
            <Typography variant="h6" gutterBottom>Testimonials</Typography>
            <p>Some genuine reviews of our customers from Google review.</p>
          </div> 
          <Testimonials from="home"/>
        </Container>

        <Container sx={{ py: 4 }} maxWidth="xl">
          <div className='center-text'>
          <Typography variant="h6" gutterBottom>Featured Brands</Typography>
          </div>  
          <Grid 
          container 
          spacing={{ xs: 2, md: 5 }} 
          // columns={{ xs: 12, sm: 8, md: 12 }}
          direction="row"
          justifyContent="center"
          alignItems="center"
          >
              {brands.map((brand,key) =>
                <Grid item xs={4} sm={3} md={1} key={`brand_${brand}`} className="content-center ">
                  <BrandCard image={brand} />
                </Grid>              
              )}
          </Grid>
        </Container>       
        
      </div>      
  );
}
