import './App.css';
import './product.css';
import {Routes,Route} from 'react-router-dom';
import OrderpotalLayout from './components/orderPortal';
import OrdersReceived from './components/orderPortal/OrdersReceived';
import PaymentPending from './components/orderPortal/PaymentPending';
import DispatchPending from './components/orderPortal/DispatchPending';
import CompletedOrders from './components/orderPortal/CompletedOrders';
import CancelledOrders from './components/orderPortal/CancelledOrders';
import AddProduct from './components/orderPortal/AddProduct';
import UpdateProduct from './components/orderPortal/UpdateProduct';
import SignIn from './components/orderPortal/SignIn';
import Home from './components/customer/Home';
import { CUSTOMER_INITIAL, ORDER_PORTAL_INITIAL, ORDER_PORTAL_SIGNIN, CUSTOMER_SHOP,CUSTOMER_NEW_ARRIVALS,CUSTOMER_COMBOS,CUSTOMER_SHIPPING_POLICY, CUSTOMER_HOT_SELLING, CUSTOMER_PAYMENT,CUSTOMER_CART, CUSTOMER_TESTIMONIALS, CUSTOMER_ACCOUNT } from './helpers/constants/RoutePaths';
import CustomerLayout from './components/customer';
import Shop from './components/customer/Shop';
import NewArrivals from './components/customer/NewArrivals';
import Combos from './components/customer/Combos';
import ShippingPolicy from './components/customer/ShippingPolicy';
import HotSelling from './components/customer/HotSelling';
import PaymentPolicy from './components/customer/PaymentPolicy';
import ShoppingCart from './components/customer/ShoppingCart';
import { Provider } from 'react-redux';
import Testimonials from './components/customer/Testimonials';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import { store } from './redux/actions/store/ConfigureStore';
import MyAccount from './components/customer/MyAccount';
function App() {
  return (
    <div>
    <ToastContainer />
    <Provider store={store}>
      <Routes>
        <Route exact path={CUSTOMER_INITIAL} element={<CustomerLayout/>}>
          <Route path={CUSTOMER_INITIAL} element={<Home/>}/>
          <Route path={`${CUSTOMER_SHOP}/:category`} element={<Shop/>}/>
          <Route path={CUSTOMER_SHOP} element={<Shop/>}/>
          <Route path={CUSTOMER_NEW_ARRIVALS} element={<NewArrivals />} />
          <Route path={CUSTOMER_HOT_SELLING} element={<HotSelling />} />
          <Route path={CUSTOMER_COMBOS} element={<Combos />} />
          <Route path={CUSTOMER_SHIPPING_POLICY} element={<ShippingPolicy />} />
          <Route path={CUSTOMER_PAYMENT} element={<PaymentPolicy />} />
          <Route path={CUSTOMER_CART} element={<ShoppingCart />} />
          <Route path={CUSTOMER_TESTIMONIALS} element={<Testimonials />} />
          <Route path={CUSTOMER_ACCOUNT} element={<MyAccount/>}/>

        </Route>

        <Route path={ORDER_PORTAL_SIGNIN} element={<SignIn/>}/>
        <Route path={ORDER_PORTAL_INITIAL} element={<SignIn/>}/>
        <Route exact path={ORDER_PORTAL_INITIAL} element={<OrderpotalLayout />}>
          <Route path="orders-received" element={<OrdersReceived />} />
          <Route path="payment-pending" element={<PaymentPending />} />
          <Route path="dispatch-pending" element={<DispatchPending />} />
          <Route path="completed-orders" element={<CompletedOrders />} />
          <Route path="cancelled-orders" element={<CancelledOrders />} />
          <Route path="add-product" element={<AddProduct />} />
          <Route path="update-product" element={<UpdateProduct />} />
        </Route>
      </Routes>
      </Provider>
    </div>
  );
}

export default App;
