import * as React from 'react';
import {useState} from 'react'
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import {Outlet} from 'react-router-dom';
import Navbar from './layout/Navbar';
import Sidebar from './layout/Sidebar';
import { useLayoutEffect } from 'react';
import MenuItems from './layout/MenuItems';
import Drawer from '@mui/material/Drawer';
import {List,Divider} from '@mui/material';
function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://mui.com/">
        Crackers Mart
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const mdTheme = createTheme({
  primaryColor: 'linear-gradient(270deg, #8C000A 0%, #BF4451 100%)',    
});

export default function OrderpotalLayout() {
  const [open, setOpen] = useState(true);
  const [isMobileView, setIsMobileView] = useState(false);
  const [drawerFlag,setDrawerFlag] = useState(false);

  const toggleDrawer = () => {
    if(isMobileView)
    setDrawerFlag(preVal => !preVal);
    else 
    setOpen(!open);
  };

  const handleSideMenuDrawer = () => {
    setDrawerFlag(!drawerFlag);
  }

  const handleResize = () => {    
    const mobileDeviceWid = window.innerWidth < 769
    setOpen(!mobileDeviceWid);    
    setIsMobileView(mobileDeviceWid)
  }
  
  useLayoutEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize',handleResize)
    };
  }, [])

  return (
    <ThemeProvider theme={mdTheme}>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        {/* Navbar  */}
        <Navbar open={open} toggleDrawer={toggleDrawer}/>
        {/* Navbar  */}
        {/* Sidebar  */}
        {!isMobileView && <Sidebar open={open} toggleDrawer={toggleDrawer}/>}
        {/* Sidebar  */}
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
          }}
        >
          <Toolbar />
          <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <Outlet />
            <Copyright sx={{ pt: 4 }} />
          </Container>
        </Box>
      </Box>

      {/* Mobile view sidebar drawer       */}
      <Drawer
          anchor={'left'}
          open={drawerFlag}
          onClose={handleSideMenuDrawer}
        >
          <Box
            sx={{ width: 230 }}
            role="presentation"
            onClick={handleSideMenuDrawer}
            onKeyDown={handleSideMenuDrawer}
          >
            <div id="mob-menu-head">
              <img src="/images/logowithtext.png" alt="Crackers Mart" width={100} />
            </div>
            <Divider />
            <List>
              <MenuItems/>               
            </List>
          </Box>
      </Drawer>
      {/* Mobile view sidebar drawer       */}

    </ThemeProvider>
  );
}