import { createSlice } from '@reduxjs/toolkit';
import { CART_ITEMS_INITIAL } from '../store/InitialStore';

export const CartItemsReducer = createSlice({ 
    name:"cartItems",
    initialState: CART_ITEMS_INITIAL,
    reducers: {
        setCartItems: (state,action) => { 
            const { payload } = action;
            state.items = payload;
        }
    } 
});

export const {setCartItems  } = CartItemsReducer.actions;
export default CartItemsReducer.reducer; 